import styled from "styled-components";

export const Wrapper = styled.div`
  form {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 6px;
  }

  .form-input {
    border-radius: 8px;
    background: #f0f0f0;
    padding: 5px 23px;
    border: none;
    outline: none;
    height: 40px;
    font-size: 16px;
    width: 230px;
    gap: 10px;
    margin-top: 20px;

    &::placeholder {
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: gray;
    }
  }
  .form-button {
    border-radius: 8px;
    background: #208667;
    width: 257px;
    height: 40px;
    padding: 8px 20px;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 40px;
  }
  .form-item {
    width: 100%;
    position: relative;
  }
  .eye-icon {
    position: absolute;
    height: 45px;
    width: 45px;
    bottom: 0px;
    right: 0px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;
