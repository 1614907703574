import styled from "styled-components";

export const StyledBox = styled.div`
  border-radius: 20px;
  border: 2px solid #ddf8f0;
  background: #fff;
  padding: 20px 23px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    border: 2px solid #208667;
  }

  h2 {
    color: #208667;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
  }

  p {
    color: #121212;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .meet_count {
    fill: #fff;
    width: 60px;
    height: 60px;
    border: 1.5px solid #208667;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #208667;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    margin-right: 20px;
  }
`;
