import { Box, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { GeneralStyle } from "./style";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import {
  meetingCreateFunc,
  meetingGetByIDFunc,
  meetingGetCities,
  meetingGetCompaniesFunc,
  updateMeetingFunc,
} from "./queryColbackFun";
import { MyButton } from "../../../../../../components";
import { PENDING } from "../../../../../../constants";
import { editInputText } from "../../../../../../utils";
const changeValue = (setValue, data) => {
  setValue("companyId", data.companyId);
  setValue("cityId", data.cityId);
  setValue("protocolNumber", data.protocolNumber);
  setValue("address", data.address);
  setValue("description", data.description);
  setValue("status", data.status);
  setValue("typeEnum", data.typeEnum);
  setValue("extraInfo", data.extraInfo ? data.extraInfo : "");
  setValue("startDate", data.startDate.slice(0, data.startDate.length - 1));
  setValue(
    "startRegistration",
    data.startRegistration.slice(0, data.startRegistration.length - 1)
  );
  setValue(
    "endRegistration",
    data.endRegistration.slice(0, data.endRegistration.length - 1)
  );
};

function MeetingGeneral({ setActive, setMeeting }) {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const meetingId = !!localStorage.getItem("newMeetnigId")
    ? JSON.parse(localStorage.getItem("newMeetnigId"))
    : null;

  const { data: companies, error } = useQuery(
    "getAllCompanies",
    meetingGetCompaniesFunc
  );

  const { data: cities } = useQuery("cities", meetingGetCities);
  const { data: meeting, isLoading: meetingisLoading } = useQuery(
    "meetingData",
    async () => await meetingGetByIDFunc(meetingId),
    {
      onSuccess: (data) => {
        if (!!data) {
          changeValue(setValue, data);
          localStorage.setItem("newMeetnigId", JSON.stringify(data));
        }
      },
    }
  );

  const { isLoading, mutate } = useMutation((props) =>
    meetingCreateFunc({ props, setActive, navigation, setMeeting, t })
  );

  const { mutate: updateMeeting, isLoading: updateMeetingLoading } =
    useMutation((props) =>
      updateMeetingFunc({ data: props, setActive, navigation, setMeeting, t })
    );

  const onSubmit = (data) => {
    const city = cities?.find((city) => city?.id === data?.cityId);
    const company = companies?.find(
      (company) => company?.id === data?.companyId
    )?.name;
    const modifiedData = {
      ...data,
      address: editInputText(data.address),
      description: editInputText(data.description),
      city: city,
      companyName: company,
      protocolNumber: data?.protocolNumber,
      status: PENDING,
    };
    if (!!meetingId) {
      updateMeeting({
        ...modifiedData,
        startDate: data.startDate + ".000Z",
        startRegistration: data?.startRegistration + ".000Z",
        endRegistration: data?.endRegistration + ".000Z",
        id: meetingId.id,
      });
    } else {
      mutate({
        ...modifiedData,
        startDate: data.startDate + ":00.000Z",
        startRegistration: data?.startRegistration + ":00.000Z",
        endRegistration: data?.endRegistration + ":00.000Z",
      });
    }
  };

  if (meetingisLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={"80vh"}
      >
        <CircularProgress
          color="success"
          style={{ width: "100px", height: "100px" }}
        />
      </Box>
    );
  }

  return (
    <GeneralStyle>
      <form onSubmit={handleSubmit(onSubmit)} className="general-list">
        <Box className="general-item">
          <label>
            {t("meetingOrgans")} *
            <Select
              {...register("companyId", { required: true })}
              className="create-select"
              value={watch("companyId") ? watch("companyId") : ""}
              required
            >
              {companies?.map((status) => (
                <MenuItem key={status?.id} value={status?.id}>
                  {status.name}
                </MenuItem>
              ))}
            </Select>
          </label>
          <label>
            {t("cityOrRegion")} *
            <Select
              {...register("cityId", { required: true })}
              className="create-select"
              value={watch("cityId") ? watch("cityId") : ""}
              required
            >
              {cities?.map((cities) => (
                <MenuItem key={cities?.id} value={cities?.id}>
                  {cities.nameUz}
                </MenuItem>
              ))}
            </Select>
          </label>
        </Box>
        <Box className="general-item">
          <label>
            {t("idProtocol")} *
            <input
              {...register("protocolNumber", { required: true })}
              type="number"
              required
              placeholder={t("companyForm.enter")}
            />
          </label>
          <label>
            {t("meetingsList.address")} *
            <input
              {...register("address", { required: true })}
              type="text"
              required
              placeholder={t("companyForm.enter")}
            />
          </label>
        </Box>
        <Box className="general-item general-items">
          <Box>
            <label>
              {t("meetingData")}
              <textarea
                {...register("description")}
                placeholder={t("companyForm.enter")}
              />
            </label>
          </Box>
          <Box>
            <label>
              {t("beginningTime")}
              <input
                {...register("startDate", { required: true })}
                type="datetime-local"
              />
            </label>
            <label>
              {t("signupTime")}
              <input
                {...register("startRegistration", { required: true })}
                type="datetime-local"
              />
            </label>
            <label>
              {t("meetingsList.finishRegister")}
              <input
                {...register("endRegistration", { required: true })}
                type="datetime-local"
              />
            </label>
          </Box>
        </Box>
        {isLoading | updateMeetingLoading ? (
          <MyButton>
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
              }}
            />
          </MyButton>
        ) : (
          <button type="submit" className="general-submit">
            {t("next")}
          </button>
        )}
      </form>
    </GeneralStyle>
  );
}
export default MeetingGeneral;
