import { IconButton } from "@mui/material";
import styled from "styled-components";

export const NotificationItemBox = styled.div`
  .notification-item-top {
    display: flex;
    gap: 16px;
    align-items: flex-start;
  }
  .notification-item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 400px;
    margin: 0;
    padding: 0;
    color: #1a1f36;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .notification-item-content-text {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .notification-item-time {
    color: var(--knock-gray-200, #a5acb8);
    line-height: 20px;
  }
`;

export const IconBox = styled(IconButton)`
  position: relative;

  .notification-count {
    position: absolute;
    top: 0;
    right: 0;
    width: 21px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    background-color: #208667;
    border-radius: 50%;
  }
`;
