import { ANY, ENTITY, ERI, FOREIGNER, INDIVIDUAL, INPASS } from "../constants";

export const status = (t, data) => {
  if (data === "ACTIVE") {
    return t("meetingCreated.meetingStatus.active");
  } else if (data === "PENDING") {
    return t("meetingCreated.meetingStatus.pending");
  } else if (data === "FINISH") {
    return t("meetingCreated.meetingStatus.finish");
  } else if (data === "CANCELED") {
    return t("meetingCreated.meetingStatus.canceled");
  } else if (data === "DISABLED") {
    return t("meetingCreated.meetingStatus.disabled");
  }
};

export const getDate = (currentDate) => {
  return (
    <>
      {currentDate?.substr(currentDate - 1, 10)}
      {"  "}
      {currentDate?.substr(11, 5)}
    </>
  );
};

export const statusText = (t) => [
  { id: 1, value: INDIVIDUAL, text: t("user.jismoniy") },
  { id: 2, value: ENTITY, text: t("user.yuridik") },
  { id: 3, value: FOREIGNER, text: t("user.chetel") },
];

export const statusLogin = (t) => [
  { id: 1, value: ERI, text: t("user.etp") },
  { id: 2, value: INPASS, text: t("user.loginetp") },
  { id: 3, value: ANY, text: t("user.any") },
];

export const StringToTime = {
  TWOMIN: 2,
  FIVEMIN: 5,
  TENMIN: 10,
  FIFTEENMIN: 15,
  TWENTYMIN: 20,
};

export const editInputText = (text) =>
  text ? text.replaceAll("'", "ʼ") : text;
