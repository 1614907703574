import { Box } from "@mui/material";
import styled from "styled-components";
import { MIN_LENGTH } from "../../constants";

export const Img = styled.img`
  width: 200px;
`;

export const Wrapper = styled.div`
  max-width: ${MIN_LENGTH}px;
  margin: auto;

  @media only screen and (max-width: 1170px) {
    padding: 0 16px;
  }
`;

export const StyledBox = styled(Box)`
  .menu-box {
    margin-top: 5px;
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: 0px 5px 5px gray;
    background-color: #fff;
  }

  .menu-item {
    padding: 5px 20px;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }
    p {
      margin: 0px;
      padding: 0px;
    }
    text-align: center;
  }

  .header-button {
    padding: 5px 10px;
    border-radius: 8px;
    background: #208667;
    min-height: 40px;
    width: 100%;
    max-width: 170px;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: 0.5s;
  }

  .header-button:hover {
    background: #208667;
    color: #fff;
  }
`;
