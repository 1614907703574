import React from "react";
import styled from "styled-components";
import { FileIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";
import { forwardRef } from "react";

const InputFile = forwardRef((props, ref) => {
  const { value, changeValue, name } = props;
  const { t } = useTranslation();

  return (
    <InputFileBox value={value}>
      <span className="file-input-text">
        {typeof value === "object"
          ? value?.name
          : t("meetingCreated.reestr.uploadFile")}
      </span>
      <img
        className="file-input-icon"
        src={FileIcon}
        width={24}
        height={24}
        alt="file icon"
      />
      <input
        type="file"
        id={name}
        name={name}
        onChange={(e) => changeValue(e.target)}
        className="file-input"
        multiple
        autocomplete="off"
        ref={ref}
      />
    </InputFileBox>
  );
});

export default InputFile;

const InputFileBox = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 0px 12px;
  border-radius: 6px;
  border: 1px solid #208667;
  background: var(--interactive-enabled, #fff);
  cursor: pointer;

  .file-input-text {
    color: ${(props) => (props.value ? "#000" : "#9d9d9d")};
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .file-input {
    display: none;
  }
`;
