import React, { useEffect, useState } from "react";
import { API } from "../../../../../services/api";
import { useQuery } from "react-query";

import { BALLOT_OPTIONS } from "../../../../../constants";
import img2 from "../../../../../assets/images/kotib/result.png";

import { QUERIES_NAME } from "../../../../../constants/apiqueries";
import ProgressBar from "../../../../../components/ProgressbBar";
import {
  InnerRightDiv,
  RightButtons,
  StatisticDiv,
  VotesDiv,
} from "../../Votes/style";
import { t } from "i18next";

function Vote({ subject, voit, index, agenda }) {
  const [voitFor, setVoitFor] = useState(0);
  const [voitRef, setVoitRef] = useState(0);
  const [voitIgn, setVoitIgn] = useState(0);

  const {
    data: voitData,
    isSuccess,
    isError,
    error,
  } = useQuery(`${QUERIES_NAME.GET_VOIT + voit?.id + index + 1}`, async () => {
    return await API.getVoitById({ id: voit.id });
  });

  const openVoits = () => {
    window.open(
      `${window.location.origin}/user/${voit?.id}/voits/${agenda?.id}`
    );
  };
  useEffect(() => {
    setVoitFor(0);
    setVoitRef(0);
    setVoitIgn(0);
    if (isSuccess) {
      voitData.data?.forEach((item) => {
        if (item.options === BALLOT_OPTIONS.FOR) {
          setVoitFor((prevState) => prevState + 1);
        } else if (item.options === BALLOT_OPTIONS.REFRAIN) {
          setVoitRef((prevState) => prevState + 1);
        } else {
          setVoitIgn((prevState) => prevState + 1);
        }
      });
    }
  }, [isSuccess, agenda]);

  if (isSuccess) {
    const sum = voitFor + voitIgn + voitRef;
    const voitForPros = isNaN((voitFor / sum) * 100)
      ? 0
      : ((voitFor / sum) * 100).toFixed();
    const voitIgnPros = isNaN((voitIgn / sum) * 100)
      ? 0
      : ((voitIgn / sum) * 100).toFixed();
    const voitRefPros = isNaN((voitRef / sum) * 100)
      ? 0
      : ((voitRef / sum) * 100).toFixed();

    return (
      <VotesDiv>
        <div>
          <img src={img2} alt="img" />
        </div>
        <InnerRightDiv>
          <h3>{t("userPage.meetingManagement.votesResult")}</h3>
          <p>{subject}</p>
          <StatisticDiv>
            <h5>{t("userPage.votes.agree", { number: voitFor })}</h5>
            <h5>{t("userPage.votes.neutral", { number: voitRef })}</h5>
            <h5>{t("userPage.votes.controversy", { number: voitIgn })}</h5>
          </StatisticDiv>

          <RightButtons>
            <ProgressBar
              percent={"" + voitForPros + "%"}
              color="linear-gradient(270deg, rgba(221, 248, 240, 0.30) 0%, rgba(6, 94, 169, 0.30) 100%), linear-gradient(270deg, #3EB250 0%, rgba(62, 178, 80, 0.60) 100%), rgba(2, 119, 21, 0.00);
"
            >
              <span>
                {voitFor} {t("participant")}
              </span>
              <span>{"" + voitForPros + "%"}</span>
            </ProgressBar>

            <ProgressBar
              percent={"" + voitRefPros + "%"}
              color="linear-gradient(90deg, rgba(255, 0, 0, 0.24) 0%, rgba(221, 248, 240, 0.30) 100%), linear-gradient(270deg, #FFC700 0%, rgba(255, 199, 0, 0.40) 100%)"
            >
              <span>
                {voitRef} {t("participant")}
              </span>
              <span>{"" + voitRefPros + "%"}</span>
            </ProgressBar>

            <ProgressBar
              percent={"" + voitIgnPros + "%"}
              color="linear-gradient(90deg, rgba(255, 199, 0, 0.21) 0%, rgba(221, 248, 240, 0.30) 100%), linear-gradient(270deg, rgba(255, 0, 0, 0.90) 0%, rgba(255, 0, 0, 0.60) 100%)"
            >
              <span>
                {voitIgn} {t("participant")}
              </span>
              <span>{"" + voitIgnPros + "%"}</span>
            </ProgressBar>
          </RightButtons>
        </InnerRightDiv>
      </VotesDiv>
    );
  }
  return null;
}

function Votes({ agenda }) {
  return (
    <>
      {agenda.length ? (
        agenda.map((item, index) =>
          item?.votingOptions.map((voit, index) =>
            Vote({ subject: item?.subject, voit, index, agenda })
          )
        )
      ) : (
        <h4>Hech narsa yo'q</h4>
      )}
    </>
  );
}

export default Votes;
