import { Typography } from "@mui/material";
import MeetingCard from "../MeetingCard";
import { useTranslation } from "react-i18next";

function ActiveMeeting({ activMeetings }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="wrapper">
        {activMeetings?.length ? (
          activMeetings.map((item, index) => {
            return <MeetingCard key={index} meetingData={item} />;
          })
        ) : (
          <Typography variant="h4" align="left">
            {t("userPage.notActiveMeetings")}
          </Typography>
        )}
      </div>
    </>
  );
}
export default ActiveMeeting;
