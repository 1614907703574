import { styled } from "styled-components";

export const StartMeeting = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const ShowButton = styled.button`
  padding: 8px 16px;
  color: #fff;
  border-radius: 8px;
  border: 1px solid #208667;
  background: #208667;
  cursor: pointer;
`;
