import { Box } from "@mui/material";
import React from "react";
import { FlexBox, FlexPage, Img, StyledBox, Wrapper } from "./style";
import { FaTelegramPlane } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import {
  EmailIcon,
  PhoneIcon,
  Protocol,
  FooterIcon,
  Napa,
  TwitterIcon,
  InstagramIcon,
  Facebook,
} from "../../assets/icons";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();
  return (
    <StyledBox>
      <Wrapper>
        <FlexBox>
          <FlexPage>
            <div className="footer-list">
              <Link to="/">
                {" "}
                <Img
                  style={{ marginLeft: -10 }}
                  src={FooterIcon}
                  alt="E-Protocol"
                />
              </Link>
              <Img src={Protocol} alt="E-Protocol" />
            </div>
            <ul className="footer-list">
              <li>
                <a
                  className="footer-link"
                  href="https://navoiyuran.uz/"
                  target="blank"
                >
                  {t("website")}
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  href="https://navoiyuran.uz/page/documents"
                  target="blank"
                >
                  {t("documents")}
                </a>
              </li>
              <li>
                <a className="footer-link" href="#">
                  {t("tutorial")}
                </a>
              </li>
              <li>
                <a className="footer-link" href="#">
                  {t("PersonalCabinet")}
                </a>
              </li>
            </ul>
          </FlexPage>
          <Box className="footer-list">
            <h4>{t("contact")}</h4>
            <Stack spacing={2} direction="row" alignItems="center">
              <img src={PhoneIcon} alt="Phone Icon" />
              <a className="footer-link" href="tel:+998795070050">
                +998(79)507-00-50
              </a>
            </Stack>
            <Stack spacing={2} direction="row" alignItems="center">
              <img src={EmailIcon} alt="Phone Icon" />
              <a className="footer-link" href="mailto:info@navoiyuran.uz">
                info@navoiyuran.uz
              </a>
            </Stack>
          </Box>
        </FlexBox>
        <FlexBox>
          <img
            className="footer-icon"
            style={{ marginLeft: -10 }}
            src={Napa}
            alt="E-Protocol"
          />
          <Box sx={{ display: "flex", gap: "15px" }}>
            <a
              className="footer-links"
              href="https://www.facebook.com/Navoiyuran-DK-109673825046889"
              target="blank"
            >
              <img src={Facebook} />
            </a>
            <a
              className="footer-links"
              href="https://t.me/Navoiyuran_official"
              target="blank"
            >
              <FaTelegramPlane color="#fff" fontSize={20} />
            </a>
            <a
              className="footer-links"
              href="https://www.instagram.com/navoiyuran/"
              target="blank"
            >
              <img src={InstagramIcon} />
            </a>
            <a
              className="footer-links"
              href="https://www.youtube.com/@navoiyuran"
              target="blank"
            >
              <BsYoutube color="#fff" fontSize={20} />
            </a>
          </Box>
        </FlexBox>
      </Wrapper>
    </StyledBox>
  );
}

export default Footer;
