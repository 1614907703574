import { CircularProgress } from "@mui/material";
import React from "react";

const ButtonSpinner = () => {
  return (
    <CircularProgress
      color="success"
      style={{ width: "24px", height: "24px" }}
    />
  );
};

export default ButtonSpinner;
