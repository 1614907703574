import styled from "styled-components";

export const LeftSide = styled.div`
  width: ${(props) => (props.activeVideo ? "100%" : "35%")};
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

export const LeftBody = styled.div`
  width: 100%;
`;

export const TopButtons = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

export const MeetingLists = styled.div`
  width: 90%;
  height: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 20px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #ddf8f0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #208667;
  }
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: 500px;
  border-radius: 12px;
  border: 1px solid #208667;
  overflow: hidden;
`;

export const MeetingCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 16px 20px;
  border-radius: 12px;
  border: 2px solid #ddf8f0;
  background: #fff;
  cursor: pointer;

  &.active {
    border-color: #208667;
  }
`;

export const CardInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export const MeetingTitle = styled.h4`
  margin: 0;
`;

export const MeetingTopic = styled.p`
  margin: 0;
  padding: 3px 10px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 8px;
  background: #ddf8f0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const VerticalLine = styled.div`
  width: 4px;
  height: 463px;
  border-radius: 10px;
  background: #ddf8f0;
`;
