import styled from "styled-components";
import {
  VotesBackground1,
  VotesBackground2,
} from "../../../../../../assets/images";

export const AgendaVoitingInnertDiv = styled.div`
  text-align: center;
`;

export const AgendaVoitingButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StatisticDiv = styled.div`
  margin: 30px 0;
`;

export const AgendaVoitingDiv = styled.div`
  height: 385px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-top: 25px;
  padding: 30px;
  padding-left: 0;
  border-radius: 12px;
  background-image: url(${VotesBackground1}), url(${VotesBackground2});
  background-position: top 0 right 0, bottom 0 left 0;
  background-repeat: no-repeat;
  background-size: 250px;
  background-color: #f6fcff;

  .agenda-voiting-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
  }
`;
