import styled from "styled-components";

export const AgendaBox = styled.div`
  .inner {
    height: 621px;
    overflow-y: auto;
    padding: 0 20px;
    margin-bottom: 30px;
    * {
      box-sizing: border-box;
    }

    .btn {
      display: block;
      padding: 8px;
      border-radius: 8px;
      border: 1px solid #208667;
      background: #fff;
    }

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #ddf8f0;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #208667;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #208667;
      border-radius: 10px;
    }
  }

  .form-box-footer {
    display: flex;
    justify-content: flex-end;
    gap: 30px;
  }

  .MuiPaper-root {
    margin: 0 !important;
    margin-bottom: 15px !important;
    /* border-radius: 0 !important; */
    box-shadow: none !important;
    border-radius: 20px !important;
  }

  .Mui-expanded {
    margin: 0 !important;
  }

  .MuiAccordionSummary-root {
    background-color: #f6fcff;
    padding: 0 20px;
  }

  .MuiAccordionSummary-content {
    justify-content: space-between !important;
  }

  .MuiAccordionDetails-root {
    padding: 0 !important;
  }
`;
