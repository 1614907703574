export const MAX_LENGTH = 1440;
export const MIN_LENGTH = 1140;
export const URL = "https://e-protocol.navoiyuran.uz";
// export const URL = "http://localhost:8585";
// export const URL = "http://localhost";
// export const URL = "";
export const BASE_URL = `${URL}/api`;

export const PAGE_SIZE = 10;

export const STATUSES = {
  ACTIVE: "ACTIVE",
  PENDING: "PENDING",
  FINISH: "FINISH",
  CANCELED: "CANCELED",
  DISABLED: "DISABLED",
};
export const ROLES = {
  SECRETARY: "SECRETARY",
  SPEAKER: "SPEAKER",
  WATCHER: "WATCHER",
  CHAIRMAN: "CHAIRMAN",
  SIMPLE: "SIMPLE",
};
export const ACTION_TYPES = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  READ: "READ",
};
export const BALLOT_OPTIONS = {
  FOR: "FOR",
  REFRAIN: "REFRAIN",
  AGAINST: "AGAINST",
};
export const ACTIVE = "ACTIVE";
export const PENDING = "PENDING";
export const FINISH = "FINISH";
export const CANCELED = "CANCELED";
export const DISABLED = "DISABLED";

export const SECRETARY = "SECRETARY";
export const SPEAKER = "SPEAKER";
export const WATCHER = "WATCHER";
export const CHAIRMAN = "CHAIRMAN";
export const SIMPLE = "SIMPLE";

export const INDIVIDUAL = "INDIVIDUAL";
export const ENTITY = "ENTITY";
export const FOREIGNER = "FOREIGNER";

export const ERI = "ERI";
export const INPASS = "INPASS";
export const ANY = "ANY";

export const USERS_ROLES = {
  USER: "ROLE_USER",
  ADMIN: "ROLE_ADMIN",
  SECRETARY: "ROLE_SECRETARY",
};

export const meetingStatus = {
  ARCHIVE: "archive",
  ACTIVE: "active",
};

export const monthObj = {
  en: [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ],

  ru: [
    "январь",
    "февраль",
    "март",
    "aпрель",
    "mай",
    "июнь",
    "Июль",
    "aвгуст",
    "cентябрь",
    "oктябрь",
    "ноябрь",
    "декабрь",
  ],
  uz: [
    "yanvar",
    "fevral",
    "mart",
    "aprel",
    "may",
    "iyun",
    "iyul",
    "avgust",
    "sentyabr",
    "oktyabr",
    "noyabr",
    "dekabr",
  ],
};

export const getDate = (date) => new Date(date);

const breakpoints = {
  xs: "320px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
};

export const devices = {
  xs: `(max-width: ${breakpoints.xs})`,
  sm: `(max-width: ${breakpoints.sm})`,
  md: `(max-width: ${breakpoints.md})`,
  lg: `(max-width: ${breakpoints.lg})`,
  xl: `(max-width: ${breakpoints.xl})`,
  "2xl": `(max-width: ${breakpoints["2xl"]})`,
};
