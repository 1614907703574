import {
  ArchiveMeetingsContainer,
  ArchiveMeetingsMainDiv,
  ArchiveMeetingsMeetingBody,
  ArchiveMeetingsTitle,
} from "./style";
import {
  ArchiveMeetingDetailLeft,
  ArchiveMeetingDetailRight,
} from "./components";

import { useState } from "react";
import { useTranslation } from "react-i18next";

function ArchiveMeeting({ archiveMeetings }) {
  const { t } = useTranslation();
  const memberRole = localStorage.getItem("memberRole")
    ? JSON.parse(localStorage.getItem("memberRole"))
    : {};
  const [meetingId, setMeetingId] = useState(
    memberRole?.meetingId ? memberRole?.meetingId : archiveMeetings[0]?.id
  );

  return (
    <ArchiveMeetingsMainDiv>
      <ArchiveMeetingsContainer>
        {!!archiveMeetings?.length ? (
          <ArchiveMeetingsMeetingBody>
            <ArchiveMeetingDetailLeft
              archiveMeetings={archiveMeetings}
              setMeetingId={setMeetingId}
              meetingId={meetingId}
            />

            <ArchiveMeetingDetailRight meetingId={meetingId} />
          </ArchiveMeetingsMeetingBody>
        ) : (
          <p>{t("userPage.notArchiveMeetings")}</p>
        )}
      </ArchiveMeetingsContainer>
    </ArchiveMeetingsMainDiv>
  );
}
export default ArchiveMeeting;
