import styled from "styled-components";
import { Box } from "@mui/material";
import { MIN_LENGTH } from "../../../constants";

export const Wrapper = styled.div`
  background: var(--bgImage);
  * {
    margin: 0;
    padding: 0;
  }

  .container {
    max-width: ${MIN_LENGTH}px;
    margin: auto;
    padding: 30px 0;
    padding-bottom: 50px;
  }

  h2 {
    color: #208667;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 134%;
    margin-top: 30px;
  }

  .information-list {
    display: flex;
    gap: 20.5px;
    margin-top: 40px;
  }

  .information-item {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    width: 262px;
    border-radius: 24px;
    background: #fff;
    box-shadow: 0px 2px 45px 0px rgba(0, 0, 0, 0.08);
  }

  .information-item-img {
    width: 202px;
    height: 184px;
    display: block;
    margin-bottom: 20px;
  }

  h4 {
    min-height: 46px;
    margin-bottom: 10px;
    color: #208667;
    text-align: center;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  p {
    min-height: 45px;
    margin-bottom: 22px;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .information-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 222px;
    height: 48px;
    border-radius: 12px;
    background: #208667;
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    box-sizing: border-box;
  }
`;
