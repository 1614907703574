import { ChildrenDiv, InnerDiv, ProgressDiv } from "./style";

const ProgressBar = ({ children, ...props }) => {
  return (
    <ProgressDiv fontColor={props.fontColor} width={props.width} {...props}>
      <ChildrenDiv center={props?.center}>{children}</ChildrenDiv>
      <InnerDiv {...props}></InnerDiv>
    </ProgressDiv>
  );
};

export default ProgressBar;
