import { monthObj } from "../../../constants";

export const notificationGetTime = (time, lan) => {
  const createDate = new Date(time);
  const isDayTime = createDate.getDate();

  // const day =
  //   isDayTime === 0
  //     ? "bugun"
  //     : isDayTime === -1
  //     ? "kecha"
  //     : createDate.getDay() + " " + monthObj[lan][createDate.getMonth() - 1];
  const day = isDayTime + " " + monthObj[lan][createDate.getMonth()];

  const hours = createDate.getHours() - 5 + ":" + createDate.getMinutes();
  return { day, hours };
};
