import styled from "styled-components";

export const ProgressDiv = styled.div`
  display: block;
  width: ${(props) => (props.width ? props.width : "290px")};
  height: 35px;
  position: relative;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Inter";
  color: ${(props) => (props.fontColor ? props.fontColor : "#333")};
  background: linear-gradient(
      180deg,
      rgba(6, 94, 169, 0.2) 0%,
      rgba(221, 248, 240, 0.04) 100%
    ),
    #fafafa;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  scale: ${(props) => (props.disabled ? 1.1 : 1)};
  border: 1px solid ${(props) => (props.disabled ? "black" : "transparent")};
  cursor: pointer;
`;

export const ChildrenDiv = styled.div`
  width: 91%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.center ? "center" : "space-between")};
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 12px;
  z-index: 9;
`;

export const InnerDiv = styled.div`
  height: 100%;
  width: ${(props) => props.percent};
  position: absolute;
  left: 0;
  top: 0;
  border-radius: ${(props) =>
    props.percent === "100%" ? "8px" : "8px 0 0 8px"};
  background: ${(props) => props.color};
`;
