import { toast } from "react-toastify";
import { API } from "../../../../../../../services/api";

export const postUserFunc = async (payload) => {
  return await API.addMemberManagers(payload)
    .then((res) => {
      const lang = localStorage.getItem("i18nextLng");
      if (lang === "uz") {
        toast.success("Foydalanuvchi kiritildi!");
      } else if (lang === "ru") {
        toast.success("Пользователь вошел!");
      } else if (lang === "en") {
        toast.success("User entered!");
      }
      return res.data;
    })
    .catch((err) => {
      console.log("Mutation err", err.response);
      const lang = localStorage.getItem("i18nextLng");
      const { errorKey, title } = err.response.data;

      if (errorKey === "memberExistByType") {
        if (lang === "uz") {
          toast.error("A'zo allaqachon ushbu nomga ega");
        }
        if (lang === "ru") {
          toast.error("У участника уже есть это имя");
        }
        if (lang === "en") {
          toast.error("The member already has this name");
        }
      }
      if (errorKey === "memberExistByTypeSecretary") {
        if (lang === "uz") {
          toast.error("A'zolar ro`yxati allaqachon bu role ga ega: KOTIB");
        }
        if (lang === "ru") {
          toast.error("В списке членов уже есть такая роль: СЕКРЕТАРЬ.");
        }
        if (lang === "en") {
          toast.error("The list of members already has this role: SECRETARY");
        }
      }
      if (errorKey === "meetingIsNotPending") {
        if (lang === "uz") {
          toast.error("Joriy yig`ilish KUTISH holatida emas!");
        }
        if (lang === "ru") {
          toast.error("Текущая встреча не в статусе - ОЖИДАЕТ!");
        }
        if (lang === "en") {
          toast.error("The current meeting is not in HOLD status!");
        }
      }
      if (err.response.data.title.includes("This user exist other meeting")) {
        if (lang === "uz") {
          toast.error("Ushbu foydalanuvchi boshqa uchrashuvda mavjud");
        }
        if (lang === "ru") {
          toast.error("У этого пользователя существует другое собрание");
        }
        if (lang === "en") {
          toast.error("This user is in another meeting");
        }
      }
    });
};

export const getMembersFunc = async () => {
  const response = await API.getMembers()
    .then((res) => res.data)
    .catch((err) => console.log("Fetch All Members Error", err));

  return response;
};

export const deleteMemberFunc = async (payload) => {
  const lang = localStorage.getItem("i18nextLng");
  return await API.deleteMember(payload)
    .then((res) => {
      if (lang === "uz") {
        toast.error("Ma'lumotlar yangilandi");
      }
      if (lang === "ru") {
        toast.error("Информация обновлена");
      }
      if (lang === "en") {
        toast.error("Information has been updated");
      }
    })
    .catch((err) => {
      console.log("Delete error", err);
      const { errorKey, title, status } = err.response.data;

      if (errorKey === "existInAgenda") {
        if (lang === "uz") {
          toast.error(
            "Bu a'zo allaqachon boshqa kun tartibida ishtirok etgan!"
          );
        }
        if (lang === "ru") {
          toast.error("Этот участник уже участвует в другой повестке дня!");
        }
        if (lang === "en") {
          toast.error("This member is already involved in another agenda!");
        }
      }
      if (errorKey === "meetingIsNotPending") {
        if (lang === "uz") {
          toast.error("Joriy yig`ilish KUTISH holatida emas!");
        }
        if (lang === "ru") {
          toast.error("Текущая встреча не в статусе - ОЖИДАЕТ!");
        }
        if (lang === "en") {
          toast.error("The current meeting is not in HOLD status!");
        }
      }
    });
};
