import styled from "styled-components";
import { ControlPanelBackgroundImg } from "../../../../assets/images";

export const MainDiv = styled.div`
  height: 385px;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-top: 25px;
  padding: 30px;
  border-radius: 12px;
  background-image: url(${ControlPanelBackgroundImg});
  background-position: top 0 right 0;
  background-repeat: no-repeat;
  background-size: 250px;
  background-color: #f6fcff;

  h3 {
    margin: 0;
    color: #208667;
  }

  p {
    margin: 0;
    margin-top: 10px;
    font-size: 12px;

    span {
      color: #208667;
    }
  }

  b {
    font-size: 12px;
  }

  h5 {
    margin: 0;
  }
`;

export const InnerRightDiv = styled.div`
  text-align: center;
`;

export const RightButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StatisticDiv = styled.div`
  margin: 30px 0;
`;
