import styled from "styled-components";
import { MIN_LENGTH } from "../../../../constants";

export const MainDiv = styled.div`
  padding-bottom: 50px;
  background: var(--bgImage);

  .create-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    svg {
      fill: #208667;
    }
  }

  h5 {
    color: #208667;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  a.special {
    color: rgba(32, 134, 103, 0.5);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }
`;

export const Container = styled.div`
  max-width: ${MIN_LENGTH}px;
  margin: auto;
`;

export const RoutesMap = styled.div`
  display: flex;
`;

export const Title = styled.h2`
  color: #208667;
`;

export const MeetingBody = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 2px 45px 0px rgba(0, 0, 0, 0.08);
`;
