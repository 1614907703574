import styled from "styled-components";

export const AgendaFormBox = styled.div`
  /* margin-bottom: 30px; */
  padding: 20px;
  padding-bottom: 30px;
  /* border-radius: 8px; */
  background-color: #f6fcff;

  .form-col {
    margin-bottom: 19px;
  }

  .form-label {
    margin-bottom: 5px;
  }

  .form-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 319px));
    column-gap: 20px;
  }

  .form-col-content {
    display: grid;
    grid-template-columns: minmax(100px, 579px) 48px;
    column-gap: 20px;
    margin-bottom: 10px;
  }
`;
