import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 80px;

  .MuiButtonBase-root:hover {
    color: #fff;
  }

  .close-btn {
    min-width: 350px;
    max-width: 1000px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 30px;
    color: white;
    position: absolute;
    top: 100px;
    left: calc(50% + 300px);
  }

  .title {
    color: #fff;
    margin-bottom: -40px;
    text-align: center;
    z-index: 1;
  }

  .webcam-container {
    position: relative;
  }
  .webcam {
  }

  .frame-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .h-blur {
    height: 100%;
    width: 100%;
    filter: blur(3px);
    background: rgba(0, 0, 0, 0.5);
  }

  .v-blur {
    height: 8%;
    width: 100%;
    filter: blur(3px);
    backdrop-filter: 3px;
    background: rgba(0, 0, 0, 0.5);
  }

  .img {
    height: 450px;
  }

  .capute-button {
    width: 500px;
    height: 50px;
  }
`;
