import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { QUERIES_NAME } from "../../../../constants/apiqueries";
import { API } from "../../../../services/api";
import { MeetingsTitle, Wrapper } from "./style";
import { useState } from "react";
import { useQueries } from "react-query";
import { STATUSES, USERS_ROLES } from "../../../../constants";
import { Box, Typography } from "@mui/material";
import ActiveMeeting from "../../components/ActiveMeeting";
import ArchiveMeeting from "../../components/ArchiveMeeting";
import Loading from "../../../../components/Loading";
import { useEffect } from "react";
import { AiOutlineCaretRight } from "react-icons/ai";
import { useMemo } from "react";

function Meetings() {
  const user = useSelector((state) => state.userData);
  const { isLogged, user_role } = useSelector((store) => store.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const compId = location?.state?.comp;
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(location.hash?.slice(1));

  const auth = useSelector((store) => store.auth);

  let basePath;

  if (auth.user_role === "ROLE_ADMIN") {
    basePath = "/admin";
  } else if (auth.user_role === "ROLE_USER") {
    basePath = "/user";
  }

  const queries = [
    {
      key: QUERIES_NAME.GET_COMPANY_MEETINGS,
      fnc: async () =>
        API.getCompanyMeetings({ userId: user.id, compId: compId }),
    },
    {
      key: QUERIES_NAME.GET_COMPANY_DATA,
      fnc: async () => API.getCompanyData({ id: compId }),
    },
  ];

  const meetingData = useQueries(
    queries.map((query) => {
      const { key, fnc } = query;
      return {
        queryKey: [`${key}`],
        queryFn: fnc,
        enabled: !!compId,
      };
    })
  );
  const loading = meetingData.some((data) => data.isLoading);
  const error = meetingData.some((data) => data.isError);
  const compInfo = meetingData[1]?.data?.data;
  const meetings = meetingData[0]?.data?.data;

  const filterMeetings = useMemo(() => {
    const archiveMeetingsData = !!meetings
      ? meetings?.filter(
          (m) => m.status === STATUSES.FINISH || m.status === STATUSES.CANCELED
        )
      : [];
    const activeMeetingsData = !!meetings
      ? meetings?.filter(
          (m) => m.status !== STATUSES.FINISH && m.status !== STATUSES.CANCELED
        )
      : [];

    return {
      archiveMeetingsData,
      activeMeetingsData,
    };
  }, [meetings]);

  useEffect(() => {
    if (!compId) {
      navigate(
        `/${
          isLogged && user_role === USERS_ROLES.ADMIN ? "admin" : "user"
        }/companies`
      );
    }
  }, [compId]);

  if (!compId) {
    return null;
  }
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return (
      <Typography sx={{ textAlign: "center" }} variant="h3">
        Nimadir noto'g'ri bajarildi
      </Typography>
    );
  }
  const changeActive = (evt) => {
    navigate(
      `/${
        isLogged && user_role === USERS_ROLES.ADMIN ? "admin" : "user"
      }/comp/meetings#${evt}`,
      { state: { comp: compInfo?.id } }
    );
    setTabValue(evt);
  };

  return (
    <Wrapper>
      <div className="container">
        <Box className="create-top">
          {auth.user_role === USERS_ROLES.USER ? null : (
            <>
              <Link to="/admin" className="special" onClick={() => "admin"}>
                {t("home")}
              </Link>
              <AiOutlineCaretRight />
            </>
          )}
          <Link to="/admin/secretary" className="special">
            <h5>{t("userPage.companies")}</h5>
          </Link>
          <AiOutlineCaretRight />
          <h5>{t("routes.controlPage.meeting")}</h5>
        </Box>
        <MeetingsTitle>{compInfo?.name}</MeetingsTitle>
        <div className="meeting-top">
          <div className="meeting-list">
            <button
              onClick={(e) => changeActive("active")}
              className={`meeting-button ${
                tabValue === "active" ? "meeting-active" : "meeting-archive"
              }`}
            >
              {t("userPage.activeMeetings")}
            </button>
            <button
              onClick={(e) => changeActive("archive")}
              className={`meeting-button ${
                tabValue === "active" ? "meeting-archive" : "meeting-active"
              }`}
            >
              {t("userPage.archiveMeetings")}
            </button>
          </div>
        </div>

        {tabValue === "active" ? (
          <ActiveMeeting activMeetings={filterMeetings?.activeMeetingsData} />
        ) : (
          <ArchiveMeeting
            archiveMeetings={filterMeetings?.archiveMeetingsData}
          />
        )}
      </div>
    </Wrapper>
  );
}
export default Meetings;
