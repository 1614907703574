import {useEffect, useRef, useState} from "react";
import SockJsClient from "react-stomp";

import {Box, CircularProgress} from "@mui/material";
import {useQuery} from "react-query";
import {API} from "../../../../services/api";
import {
    AvatarDiv,
    ChatContainer,
    ChatDiv,
    LeftMessageText,
    LeftMessages,
    MessageDiv,
    MessageTime,
    NoMessage,
    RightMessageText,
    RightMessages,
    SendMessage,
} from "./style";
import {useContext} from "react";
import {AgendaContext} from "../../pages/MeetingDetails/context/MeetingDetailContext";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {editInputText} from "../../../../utils";
import {URL} from "../../../../constants";

const Chat = ({value}) => {
    const {activeAgenda} = useContext(AgendaContext);
    const [message, setMessage] = useState("");
    const [allMessages, setAllMessages] = useState([]);
    const user = useSelector((state) => state.userData);
    const inputRef = useRef();
    const chatRef = useRef();
    const sendRef = useRef();
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("token").split(" ")[1];
    let url = `${URL}/websocket/logger/?access_token=${token}`;
    let clientRef;

    const {
        isLoading,
        data,
        refetch: refetchChatHistory,
    } = useQuery(
        ["getChatHistory"],
        async () => API.getChatHistory(activeAgenda?.id),
        {
            onSuccess: (data) => setAllMessages(data.data.messages),
        }
    );

    const onConnect = () => {
        console.log("Connected to STOMP server");
        setLoading(false);
    };

    const onDisconnect = () => {
        console.log("Disconnected from STOMP server");
        setLoading(true);
    };

    const onError = (err) => {
        console.error("Error:", err);
        setLoading(true);
    };

    const handleMessage = (e) => {
        e.preventDefault();
        if (inputRef.current.value.length > 1) {
            clientRef.sendMessage(
                "/topic/info",
                JSON.stringify({
                    userId: user.id,
                    topicId: data.data.topicId,
                    message: message,
                })
            );

            inputRef.current.value = "";
        }

        sendRef.current.style.pointerEvents = "none";
        sendRef.current.style.opacity = 0.5;
    };

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [allMessages, activeAgenda?.id, value]);

    useEffect(() => {
        refetchChatHistory();
    }, [activeAgenda?.id]);

    const handleWrite = (e) => {
        setMessage(editInputText(e.target.value));
        if (e.target.value.length > 1) {
            sendRef.current.style.pointerEvents = "all";
            sendRef.current.style.cursor = "pointer";
            sendRef.current.style.opacity = 1;
        } else {
            sendRef.current.style.pointerEvents = "none";
            sendRef.current.style.opacity = 0.5;
        }
    };

    if (isLoading) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={"80vh"}
            >
                <CircularProgress
                    color="success"
                    style={{width: "100px", height: "100px"}}
                />
            </Box>
        );
    }

    const stompConfig = {
        url: url,
        autoReconnect: true,
        onConnectFailure: (e) => {
            console.log(e)
        },
        topics: [`/topic/info/${data?.data?.topicId}`],
        onMessage: (msg) => {
            console.log(msg);
            setAllMessages([...allMessages, msg]);
        },
        ref: (client) => {
            clientRef = client;
        },
        onConnect,
        onDisconnect,
        onError,
    };

    return (
        <>
            {<SockJsClient {...stompConfig} />}
            <ChatDiv style={{backgroundColor: "#DDF8F0"}}>
                <ChatContainer ref={chatRef}>
                    {allMessages.length > 0 ? (
                        allMessages.map((message) => {
                            const nullName = message.user.fullName;
                            const userImg = message.user.fullName
                                ? `${nullName.replaceAll(" ", "")[0]}.${
                                    nullName.replaceAll(" ", "")[2]
                                }.${nullName.replaceAll(" ", "")[4]}`
                                : message.user.lastName[0];

                            return message.userId === user.id ? (
                                <RightMessages key={message.id}>
                                    <MessageDiv>
                                        <RightMessageText>{message.message}</RightMessageText>
                                        <MessageTime isSent="true">
                                            {message.time.split(" ")[1].split(":")[0] +
                                                ":" +
                                                message.time.split(" ")[1].split(":")[1]}
                                        </MessageTime>
                                    </MessageDiv>
                                    <AvatarDiv>{userImg}</AvatarDiv>
                                </RightMessages>
                            ) : (
                                <LeftMessages key={message.id}>
                                    <AvatarDiv>{userImg}</AvatarDiv>
                                    <MessageDiv>
                                        <LeftMessageText>{message.message}</LeftMessageText>
                                        <MessageTime>
                                            {message.time.split(" ")[1].split(":")[0] +
                                                ":" +
                                                message.time.split(" ")[1].split(":")[1]}
                                        </MessageTime>
                                    </MessageDiv>
                                </LeftMessages>
                            );
                        })
                    ) : (
                        <NoMessage>{t("userPage.chatInfoNot")}</NoMessage>
                    )}
                </ChatContainer>
                <SendMessage>
                    <form onSubmit={handleMessage}>
                        <input
                            ref={inputRef}
                            type="text"
                            placeholder={t("userPage.sendMessagePlaceholder")}
                            onChange={(e) => handleWrite(e)}
                        />
                        <button type="submit" ref={sendRef}>
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none">
                                <path
                                    d="M1.20422 10.5L11.6992 6L1.20422 1.5L1.19922 5L8.69922 6L1.19922 7L1.20422 10.5Z"
                                    fill="white"
                                />
                            </svg>
                        </button>
                    </form>
                </SendMessage>
            </ChatDiv>
        </>
    );
};

export default Chat;
