import {useContext, useEffect, useState} from "react";
import MyButton from "../../../../components/UI/MyButton/MyButton";
import { QUERIES_NAME } from "../../../../constants/apiqueries";
import {
  CardInner,
  LeftBody,
  LeftSide,
  MeetingCard,
  MeetingLists,
  MeetingTitle,
  MeetingTopic,
  ShowButton,
  StartMeeting,
  TopButtons,
  VerticalLine,
  VideoContainer,
} from "./style";
import { AgendaContext } from "../../pages/MeetingDetails/context/MeetingDetailContext";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { USERS_ROLES, meetingStatus } from "../../../../constants";
import { LeftArrow } from "../../../../assets/icons";
import { useSelector } from "react-redux";
import VideoMeeting from "../VideoMeeting";
import { useTranslation } from "react-i18next";
import {userData} from "../../../../redux/models/userData";
import {API} from "../../../../services/api";

const MeetingDetailsLeft = ({ activeVideo, setActiveVideo }) => {
  const { agendas, activeAgenda, setActiveAgenda, setIsVotes } =
    useContext(AgendaContext);
  const { isLogged, user_role } = useSelector((store) => store.auth);
  const userData = useSelector((store) => store.userData);
  console.log(user_role,"user_role")

  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { meetingId } = useParams();
  const [meeting, setMeeting] = useState();
  console.log(meeting,"meeting")
  useEffect(() => {
    API.getMeeting({ meetingId }).then(response=>{
      setMeeting(response.data) ;
    })

  }, []);
  const memberRole = localStorage.getItem("memberRole")
    ? JSON.parse(localStorage.getItem("memberRole"))
    : {};

  const [active, setActive] = useState(
    memberRole.agendaId ? memberRole.agendaId : activeAgenda?.id
  );

  const changeAgenda = (value) => {
    localStorage.setItem(
      "memberRole",
      JSON.stringify({ ...memberRole, agendaId: value.id })
    );
    setActive(value.id);
    setActiveAgenda(value);
    setIsVotes(false);
  };

  const handleClick = () => {
    navigate(
      `${
        isLogged && user_role === USERS_ROLES.ADMIN ? "/admin" : "/user"
      }/comp/meetings#archive`,
      { state: state }
    );
  };
  return (
    <LeftSide activeVideo={activeVideo}>
      <LeftBody>
        <TopButtons>
          {state?.status === meetingStatus.ARCHIVE ? (
            <>
              <MyButton color="#208667" size={22} onClick={handleClick}>
                <img src={LeftArrow} alt="Icon" />
              </MyButton>
              <MyButton color="#208667" size={22} weight={700}>
                {t("meetingStarted.navbar.agenda")}
              </MyButton>
            </>
          ) : (
            <>
              <MyButton
                color={`${!activeVideo ? "#fff" : "#208667"}`}
                border="#208667"
                background={`${!activeVideo ? "#208667" : "#fff"}`}
                onClick={() => setActiveVideo(false)}
              >
                {t("meetingStarted.navbar.agenda")}
              </MyButton>
              <MyButton
                color={`${activeVideo ? "#fff" : "#208667"}`}
                border="#208667"
                background={`${activeVideo ? "#208667" : "#fff"}`}
                onClick={() => setActiveVideo(true)}
              >
                {t("userPage.Videoconference")}
              </MyButton>
            </>
          )}
        </TopButtons>
        <MeetingLists style={{ display: !activeVideo ? "flex" : "none" }}>
          {agendas?.map((meeting) => (
            <MeetingCard
              key={meeting.id}
              className={`${active === meeting.id ? "active" : ""}`}
              onClick={() => changeAgenda(meeting)}
            >
              <CardInner>
                <MeetingTitle>{t("userPage.subject")}</MeetingTitle>
                <MeetingTopic>{meeting.subject}</MeetingTopic>
              </CardInner>
              <CardInner>
                <MeetingTitle>{t("meetingCreated.roles.speaker")}</MeetingTitle>
                <MeetingTopic>{meeting.userName}</MeetingTopic>
              </CardInner>
            </MeetingCard>
          ))}
        </MeetingLists>
        <VideoContainer style={{ display: activeVideo ? "block" : "none" }}>
          <VideoMeeting activeVideo={activeVideo} meeting={meeting} userRole={user_role} userData={userData}/>
        </VideoContainer>
      </LeftBody>
      <VerticalLine style={{ display: !activeVideo ? "block" : "none" }} />
    </LeftSide>
  );
};

export default MeetingDetailsLeft;
