import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ImageDiv,
  ProtocolBottom,
  ProtocolDiv,
  ProtocolRight,
  ProtocolTop,
} from "./style";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";

import image from "../../../../assets/images/chairman.png";
import sign from "../../../../assets/icons/sign.png";
import download from "../../../../assets/icons/download.png";
import SignWithERI from "./SignWithERI";
import { ProtocolIcon } from "../../../../assets/icons";
import { BASE_URL, CHAIRMAN, SECRETARY, SIMPLE } from "../../../../constants";
import { t } from "i18next";
import { useQuery } from "react-query";
import { API } from "../../../../services/api";
import MyButton from "../../../../components/UI/MyButton/MyButton";
import { green } from "@mui/material/colors";
import { useMemo } from "react";

const ChairmanProtocol = ({ setIsProtocol, membersData }) => {
  const [open, setOpen] = useState(false);
  const { meetingId } = useParams();
  const user = useSelector((state) => state.userData);
  const { data, refetch, isFetching } = useQuery("signInProtocol", async () => {
    const response = await API.getSignInProtocol({ id: meetingId })
      .then((res) => res.data)
      .catch((err) => console.log(err));

    return response;
  });

  const protocolSignInMember = useMemo(() => {
    let a = 0;
    let b = 0;
    if (!!data) {
      data.forEach((item) => {
        if (item.memberTypeEnum === CHAIRMAN) {
          a += 1;
        } else if (item.memberTypeEnum === SIMPLE) {
          b += 1;
        }
      });
    }

    return { chairman: a, simple: b };
  }, [data]);

  const memberRole = localStorage.getItem("memberRole")
    ? JSON.parse(localStorage.getItem("memberRole"))
    : {};

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ProtocolDiv>
      <ImageDiv>
        <img src={image} alt="protocol" />
      </ImageDiv>
      <ProtocolRight>
        <ProtocolTop>
          <h2>{t("userPage.protocol.protokol")}</h2>

          <div className="user-protocol-signin">
            <div className="users">
              <p>
                <span
                  className="user-protocol-signin--text"
                  style={{ paddingLeft: "5px" }}
                >
                  {t("chairMan")}
                </span>
                :{" "}
                <span style={{ paddingLeft: "5px" }}>
                  {protocolSignInMember.chairman}
                </span>
              </p>
              <p className="user-protocol-signin--text_box">
                <span
                  className="user-protocol-signin--text"
                  style={{ paddingRight: "5px" }}
                >
                  {t("companyForm.companyParticipants")}
                </span>
                :{" "}
                <span style={{ paddingLeft: "5px" }}>
                  {console.log(membersData,"membersData")}
                  {console.log(membersData?.length,"membersData.length")}
                  {protocolSignInMember.simple} /{" "}
                  {!!membersData && membersData.length}
                </span>
              </p>
            </div>
            {isFetching ? (
              <MyButton>
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                  }}
                />
              </MyButton>
            ) : (
              <MyButton onClick={() => refetch()}>
                <ReplaySharpIcon color="success" />
              </MyButton>
            )}
          </div>
          {/* <h5>
            1Loyiha ofisining rejalashtirilgan navbatdagi majlisining 1-masalasi
            muhokamasiyuzasidan tuzilgan protokol
          </h5> */}
        </ProtocolTop>
        <ProtocolBottom>
          {memberRole.memberTypeEnum === SECRETARY && (
            <button onClick={() => setIsProtocol(true)}>
              <span>{t("userPage.protocol.editProtokol")}</span>
              <img src={ProtocolIcon} alt="edit icon" />
            </button>
          )}

          <button onClick={handleClickOpen}>
            <span>{t("userPage.protocol.protocolSgin")}</span>
            <img src={sign} alt="sign" />
          </button>

          <a
            href={BASE_URL + "/meeting/generate-pdf?meetingId=" + meetingId}
            target="_blank"
          >
            <span>{t("userPage.downloadProtocol")}</span>
            <img src={download} alt="download" />
          </a>
        </ProtocolBottom>
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <SignWithERI
              meetingId={meetingId}
              userId={user.id}
              login={user.login}
              handleClose={handleClose}
            />
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </ProtocolRight>
    </ProtocolDiv>
  );
};

export default ChairmanProtocol;
