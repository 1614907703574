export const userData = {
  state: {}, // initial state
  reducers: {
    // handle state changes with pure functions
    userData(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
  }),
};
