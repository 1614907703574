import { FileDiv, FilesDiv, InnerFiles, NoMessage } from "./style";
import {
  AiOutlineFileExcel,
  AiOutlineFileImage,
  AiOutlineFilePdf,
  AiOutlineFileWord,
} from "react-icons/ai";
import { BsFillFileEarmarkImageFill } from "react-icons/bs";
import { FaRegFileAudio, FaRegFileVideo } from "react-icons/fa";
import { MdOutlineFilePresent } from "react-icons/md";
import { VscJson } from "react-icons/vsc";
import { BASE_URL } from "../../../../constants";
import { useMutation, useQuery } from "react-query";
import { QUERIES_NAME } from "../../../../constants/apiqueries";
import { useParams } from "react-router-dom";
import { API } from "../../../../services/api";
import { Box, CircularProgress } from "@mui/material";
import React, { useContext } from "react";
import { AgendaContext } from "../../pages/MeetingDetails/context/MeetingDetailContext";
import { useTranslation } from "react-i18next";
import { DeleteIcon, PlusIcon } from "../../../../assets/icons";
import { MyButton } from "../../../../components";
import { green } from "@mui/material/colors";
import { toast } from "react-toastify";
import { useState } from "react";

function Files() {
  const [deletFileId, setDeleteFileId] = useState(null);
  const { activeAgenda, getAgendas } = useContext(AgendaContext);
  const { meetingId } = useParams();
  const { t } = useTranslation();
  const {
    isLoading: fileLoading,
    isError,
    error,
    data: meetingFiles,
    refetch,
  } = useQuery([QUERIES_NAME.GET_FILES, activeAgenda?.id], async () => {
    const response = await API.getMeetingFiles(meetingId)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    return response;
  });

  const deleteFileData = useMutation(async (id) => {
    return await API.deleteAgendaFile(id)
      .then((res) => {
        toast.success("File muvafaqiyarli o'chirildi");
        refetch();
      })
      .catch((err) => {
        toast.error("Fail o'chirilmadi");
      });
  });

  const { mutate: agendaFileCreate, isLoading: agendaFileCreateLoading } =
    useMutation(async (agendaFiles) => {
      const fileData = new FormData();
      for (let file of agendaFiles?.files) {
        fileData.append("files", file);
      }
      fileData.append("agendaId", activeAgenda.id);

      return await API.createAgendaFile(fileData)
        .then((res) => {
          toast.success("Fayil yuklandi");
          refetch();
        })
        .catch((err) => {
          toast.error("file yuklanmadi");
        });
    });

  if (fileLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={"80vh"}
      >
        <CircularProgress
          color="success"
          style={{ width: "100px", height: "100px" }}
        />
      </Box>
    );
  }
  if (isError) {
    return <p>Xatolik yuzaga keldi {error}</p>;
  }

  const deleteFile = (fileId) => {
    setDeleteFileId(fileId);
    deleteFileData.mutate(fileId);
  };

  const downloadFile = ({ fileId, name }) => {
    const token = localStorage.getItem("token");
    fetch(BASE_URL + "/agenda-file/" + fileId, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download = name;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  function fileTypeIcon(type) {
    const excel =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const word =
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    const presentation =
      "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    const jsonFile = "application/json";
    const pdf = "application/pdf";
    const video = "video/mp4";
    const audioMpeg = "audio/mpeg";
    const image = "image/jpeg";
    const imgPNG = "image/png";

    if (type === excel) {
      return <AiOutlineFileExcel fontSize={30} className="text-success" />;
    } else if (type === word) {
      return <AiOutlineFileWord fontSize={30} style={{ color: "#132e85" }} />;
    } else if (type === pdf) {
      return <AiOutlineFilePdf fontSize={30} className="text-danger" />;
    } else if (type === imgPNG) {
      return <BsFillFileEarmarkImageFill fontSize={30} className="text-info" />;
    } else if (type === image) {
      return <AiOutlineFileImage fontSize={30} style={{ color: "#132e85" }} />;
    } else if (type === video) {
      return <FaRegFileVideo fontSize={30} className="text-dark" />;
    } else if (type === jsonFile) {
      return <VscJson fontSize={30} className="text-warning" />;
    } else if (type === audioMpeg) {
      return <FaRegFileAudio fontSize={30} className="text-danger" />;
    } else if (type === presentation) {
      return <MdOutlineFilePresent fontSize={30} className="text-danger" />;
    }
  }

  return (
    <FilesDiv>
      {agendaFileCreateLoading ? (
        <MyButton className="add-file-btn">
          <CircularProgress
            size={20}
            sx={{
              color: green[500],
            }}
          />
        </MyButton>
      ) : (
        <label htmlFor="file" className="add-file-btn">
          <img
            className="form-btn-img"
            src={PlusIcon}
            alt="add-button"
            width={38}
            height={28}
          />

          <input
            type="file"
            id="file"
            style={{ display: "none" }}
            onChange={(e) => agendaFileCreate(e.target)}
            multiple
            accept="image/*,application/json,application/pdf,video/mp4,udio/mpeg,mage/jpeg,mage/png"
          />
        </label>
      )}

      <InnerFiles>
        {meetingFiles?.length > 0 ? (
          meetingFiles?.map((file) => {
            return file.agenda === activeAgenda?.id ? (
              <FileDiv key={file.id}>
                <button
                  onClick={() =>
                    downloadFile({ fileId: file.id, name: file.name })
                  }
                >
                  <div>{fileTypeIcon(file.type)}</div>
                  <span>{file.name}</span>
                </button>
                <div className="btns-control">
                  {deleteFileData.isLoading && deletFileId === file.id ? (
                    <MyButton className="btn delete-btn">
                      <CircularProgress
                        size={24}
                        sx={{
                          color: green[500],
                        }}
                      />
                    </MyButton>
                  ) : (
                    <MyButton
                      className="btn delete-btn"
                      onClick={() => deleteFile(file.id)}
                    >
                      <img
                        src={DeleteIcon}
                        alt="DeleteIcon"
                        width={36}
                        height={36}
                      />
                    </MyButton>
                  )}
                </div>
              </FileDiv>
            ) : null;
          })
        ) : (
          <NoMessage>{t("userPage.filesNot")}</NoMessage>
        )}
      </InnerFiles>
    </FilesDiv>
  );
}
export default Files;
