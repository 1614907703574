import { API } from "../../services/api";

const initialState = [];

export const getModerUserData = {
  state: initialState, // initial state
  reducers: {
    // handle state changes with pure functions
    getModerUserData(state, payload) {
      const newRes = [...state, payload];
      return payload;
    },
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async getAllModerUserAsync() {
      await API.getModerUserData()
        .then((res) => {
          // console.log('COMPAI+NIES', res.data);
          dispatch.getModerUserData.getModerUserData(res?.data);
        })
        .catch((error) => console.log("Auth dispatch  error", error));
    },
  }),
};
