import React from "react";
import styled from "styled-components";
import { DeleteIcon } from "../../../../../../../../assets/icons";

const DeleteButton = ({ ...args }) => {
  return (
    <DeleteButtonBox {...args}>
      <img
        className="form-btn-img"
        src={DeleteIcon}
        alt="add-button"
        width={24}
        height={24}
      />
    </DeleteButtonBox>
  );
};

export default DeleteButton;

const DeleteButtonBox = styled.button`
  display: block !important;
  width: 100%;
  max-width: ${(props) => (props.width ? props.width : "100%")};
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #208667;
  background: #fff;
  cursor: pointer;
`;
