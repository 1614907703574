import { memo } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { API } from "../../../../../../../services/api";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../../../../../constants";
import { useTranslation } from "react-i18next";

const AgendaInfo = ({ data, count }) => {
  const { meetingId } = useParams();
  const { t } = useTranslation();

  const timeData = {
    TWOMIN: t("pages.agenda.minute2"),
    FIVEMIN: t("pages.agenda.minute5"),
    TENMIN: t("pages.agenda.minute10"),
    FIFTEENMIN: t("pages.agenda.minute15"),
    TWENTYMIN: t("pages.agenda.minute20"),
  };

  const { data: agendaFiles, isLoading } = useQuery(
    ["getAgendaFile", meetingId?.id],
    async () => {
      const res = await API.getMeetingFiles(meetingId)
        .then((res) => res.data)
        .catch((err) => console.log(err));
      return res;
    }
  );

  const downloadFile = ({ fileId, name }) => {
    const token = localStorage.getItem("token");
    fetch(BASE_URL + "/agenda-file/" + fileId, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download = name;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  return (
    <AgendaInfoBox>
      <h3 className="section-title">
        {count} - {t("agenda")}
      </h3>

      <div className="section-box">
        <ul className="section-list">
          <li className="section-item">
            <span className="section-text1">{t("agenda")}</span>
            <p className="section-text2">{data.subject}</p>
          </li>

          <li className="section-item">
            <span className="section-text1">{t("timeToAgenda")}</span>
            <p className="section-text2">{timeData[data.debateEnum]}</p>
          </li>

          <li className="section-item">
            <span className="section-text1">
              {t("meetingCreated.roles.speaker")}
            </span>
            <p className="section-text2">{data.speakerId}</p>
          </li>

          <li className="section-item">
            <span className="section-text1">{t("break")}</span>
            <p className="section-text2">{timeData[data.speakTimeEnum]}</p>
          </li>
        </ul>

        <div className="file-list-box">
          <span className="section-text1">{t("meetingFiles")}</span>
          <ul className="file-list">
            {agendaFiles
              ?.filter((file) => file?.agenda === data.id)
              ?.map((el, idx) => (
                <li className="file-list-item">
                  <button
                    onClick={() =>
                      downloadFile({ fileId: el.id, name: el.name })
                    }
                    className="file-item-link"
                  >
                    <span className="number">{idx + 1}</span>
                    <span>{el?.name}</span>
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </AgendaInfoBox>
  );
};

export default memo(AgendaInfo);

const AgendaInfoBox = styled.div`
  padding: 20px 0 20px 0 !important;
  color: #10131b;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  .section-title {
    margin-bottom: 20px !important;
    font-size: 22px;
  }

  .section-box {
    padding: 20px !important;
    border-radius: 12px;
    border: 1px solid #ddf8f0;
  }

  .section-list,
  .file-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 357px));
    column-gap: 62px;
    row-gap: 15px;
  }

  .section-text1 {
    color: #5d6b8a;
    font-size: 14px;
    letter-spacing: 0.5px;
  }

  .section-text2 {
    color: #10131b;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  .file-list-box {
    margin-top: 15px !important;
  }

  .file-list-item {
  }

  .file-item-link {
    min-height: 37px;
    display: flex;
    align-items: center;
    column-gap: 7px;
    padding: 0 9px !important;
    color: #000;
    text-align: center;
    font-family: "Public Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid #ddf8f0;
    background-color: transparent;
    cursor: pointer;
  }

  .number {
    width: 73px;
    text-align: center;
  }
`;
