import React, { useState } from "react";
import { DeleteIcon, EditIcon, SearchIcon } from "../../../../../assets/icons";
import styled from "styled-components";
import { Box, CircularProgress } from "@mui/material";
import { useQuery } from "react-query";
import { API } from "../../../../../services/api";
import { QUERIES_NAME } from "../../../../../constants/apiqueries";
import { MyButton } from "../../../../../components";
import { green } from "@mui/material/colors";
import { Link } from "react-router-dom";
import routeNames from "../../../../../constants/routeNames";
import { useTranslation } from "react-i18next";
import { editInputText } from "../../../../../utils";

const SearchUser = ({ deleteUserLoading, deleteUser }) => {
  const [tooltipActive, setTooltipActive] = useState(false);
  const [value, setValue] = useState("");
  const [activeBtn, setActiveBtn] = useState(null);
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(
    [QUERIES_NAME.GET_USER_SEARCH_DATA, value],
    async () => {
      const response = await API.getModerUserSearch(value).then((res) => {
        return res.data;
      });
      return response;
    }
  );
  document.body.onclick = (evt) => {
    if (evt.target.parentElement !== null) {
      const element = (value) => evt.target.parentElement.matches("." + value);
      if (
        element("search-list") ||
        element("search-label") ||
        element("search-box") ||
        element("search-list") ||
        element("user-search-delete") ||
        element("menu-box")
      ) {
        setTooltipActive(true);
      } else {
        setTooltipActive(false);
      }
    }
  };

  const handleChange = (event) => {
    setValue(editInputText(event.target.value));
  };

  return (
    <SearchBox className="search-box">
      <label onClick={() => setTooltipActive(true)} className="search-label">
        <img src={SearchIcon} alt="SearchIcon" />
        <input type="text" placeholder={t("search")} onChange={handleChange} />
      </label>

      {tooltipActive && (
        <SearchList className="search-list">
          {isLoading ? (
            <Box
              style={{ display: "flex", justifyContent: "center" }}
              className="loading-box"
            >
              <CircularProgress
                color="success"
                style={{ width: "40px", height: "40px" }}
              />
            </Box>
          ) : data?.length > 0 ? (
            <ul className="search-list">
              {data?.map((user) => (
                <li className="search-item">
                  <span>{user?.fullName}</span>

                  <Box
                    className="menu-box"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {deleteUserLoading && user.id === activeBtn ? (
                      <MyButton disabled={true} padding="5px 15px">
                        <CircularProgress
                          size={24}
                          sx={{
                            color: green[500],
                          }}
                        />
                      </MyButton>
                    ) : (
                      <MyButton
                        padding="5px"
                        className="user-search-delete"
                        onClick={() => {
                          setActiveBtn(user?.id);
                          deleteUser(user?.id);
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          alt="DeleteIcon"
                          className="delete-icon"
                        />
                      </MyButton>
                    )}
                    <Link
                      state={{ useresId: user?.id }}
                      to={routeNames.ADMIN_USER_EDIT}
                    >
                      <img src={EditIcon} alt="EditIcon" />
                    </Link>
                  </Box>
                </li>
              ))}
            </ul>
          ) : (
            !!data && <p>{t("noUser")}</p>
          )}
        </SearchList>
      )}
    </SearchBox>
  );
};

export default SearchUser;

const SearchBox = styled.div`
  width: 100%;
  max-width: 631px;
  position: relative;

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`;

const SearchList = styled.div`
  position: absolute;
  top: 34px;
  width: 100%;
  max-width: 631px;
  max-height: 250px;
  overflow-y: auto;
  padding: 30px 16px;
  border-radius: 0px 0px 8px 8px;
  background-color: #fff;
  box-shadow: -5px 10px 25px 0px rgba(0, 0, 0, 0.1);

  .search-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
  }

  .search-item::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ddf8f0;
    content: "";
  }

  .search-item:last-child::after {
    display: none;
    content: "";
  }
`;
