import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Breadcrumbs, CircularProgress, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { EditForm, StyledBoxEdit, Wrapper } from "./style";
import routeNames from "../../../../constants/routeNames";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useState } from "react";
import { API } from "../../../../services/api";
import { dispatch } from "../../../../redux";
import { useForm } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LikeIcon } from "../../../../assets/icons";
import { MyButton } from "../../../../components";
import { green } from "@mui/material/colors";
import { BASE_URL } from "../../../../constants";
import { useTranslation } from "react-i18next";
const defaultValue = (userData) => {
  return {
    email: userData?.email,
    phoneNumber: userData?.phoneNumber,
    password: "",
    confirmPassword: "",
  };
};

function EditUserData() {
  const { isLogged, user_role } = useSelector((store) => store.auth);
  const userData = useSelector((store) => store.userData);
  const [showPassword, setShowPassword] = useState(false);
  const [userImage, setUserImg] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValue(userData),
  });

  const { mutate: fileMutate, isLoading: fileLoading } = useMutation(
    async (payload) => {
      return await API.createAgendaFile(payload)
        .then((res) => {
          setUserImg(res.data);

          dispatch.userData?.userData({
            ...userData,
            imageUrl: `${BASE_URL}/agenda-file/${res.data[0]?.id}`,
          });

          toast.success(t("myProfile.successFullPhoteUpload"));
        })
        .catch((err) => {
          toast.error(t("myProfile.errorPhoteUpload"));
        });
    }
  );

  const { isLoading, mutate } = useMutation(async (newData) => {
    return await API.updateUserData(newData)
      .then((res) => {
        toast.success(t("myProfile.infoUpdateSuccessful"));
        dispatch.userData?.userData(res.data);
        navigate(routeNames.PROFILE);
      })
      .catch((err) => {
        console.log("Mutation err", err);
        toast.error(t("adminToast.infoError"));
      });
  });

  const { mutate: deleteUserImage, isLoading: deleteUserImageLoading } =
    useMutation(async (id) => {
      return await API.userProfileImageDelete(id)
        .then((res) => {
          toast.success(t("myProfile.deleteUserPhotesuccessfull"));
          dispatch.userData?.userData({
            ...userData,
            imageUrl: "",
          });
          return res.data;
        })
        .catch((err) => {
          toast.error(t("myProfile.deleteUserPhoteError"));
          console.log(err);
        });
    });

  const redirect =
    isLogged && user_role === "ROLE_ADMIN"
      ? routeNames.ADMIN
      : routeNames.USER_COMPANIES;

  const onSubmit = (data) => {
    console.log(userData);
    if (data?.password === data?.confirmPassword) {
      const newData = {
        activated: userData?.activated,
        authTypeEnum: userData?.authTypeEnum,
        authorities: userData?.authorities,
        email: data?.email,
        fullName: userData?.fullName,
        lastName: userData?.lastName ? userData?.lastName : userData?.fullName,
        groupEnum: userData?.groupEnum,
        id: userData?.id,
        inn: userData?.inn,
        login: userData?.login,
        passport: userData?.passport,
        password: data?.password,
        phoneNumber: data?.phoneNumber,
        pinfl: userData?.pinfl,
        resident: userData?.resident,
        imageUrl: userImage
          ? `${BASE_URL}/agenda-file/${userImage[0]?.id}`
          : null,
      };
      if (data.password) {
        if (data?.password?.length <= 3 || data?.confirmPassword?.length <= 3) {
          return toast.error(t("myProfile.parolLengthError"));
        }
        mutate(newData);
      } else {
        mutate({ ...newData, password: null });
      }
    } else {
      return toast.error(t("myProfile.parolError"));
    }
  };

  const ErrorText = ({ text }) => (
    <p
      style={{
        position: "absolute",
        bottom: "-30px",
        color: "red",
      }}
    >
      {text}
    </p>
  );

  const handleClick = (file) => {
    const newFormData = new FormData();
    newFormData.append("files", file);
    fileMutate(newFormData);
  };

  const deleteUserImg = () => {
    deleteUserImage(userData?.id);
  };

  return (
    <Wrapper>
      <div className="container">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="medium" color="disabled" />}
          aria-label="breadcrumb"
          className="breadcrumbs"
        >
          <Link
            underline="hover"
            key="1"
            color="inherit"
            to={redirect}
            className="link"
          >
            {t("routes.menu")}
          </Link>

          <Typography className="profile" key="2" variant="h6" color="primary">
            {t("routes.profile")}
          </Typography>
        </Breadcrumbs>
        <StyledBoxEdit>
          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Box className="img-box">
              {fileLoading ? (
                <span className="img-loader">
                  {" "}
                  <CircularProgress
                    color="success"
                    style={{ width: "80px", height: "80px" }}
                  />
                </span>
              ) : !!userData?.imageUrl ? (
                <img src={userData?.imageUrl} alt="LikeIcon" />
              ) : (
                <img src={LikeIcon} alt="LikeIcon" />
              )}
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <label htmlFor="image-upload" className="profile-image-upload">
                {t("myProfile.newPhot")}
                <input
                  id="image-upload"
                  name="image-upload"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleClick(e.target.files[0])}
                />
              </label>
              <MyButton
                border="#ff0000"
                color="red"
                background="#fff"
                weight="500"
                backgroundHover="red"
                hoverColor="#fff"
                onClick={deleteUserImg}
              >
                {deleteUserImageLoading ? (
                  <CircularProgress
                    color="success"
                    style={{ width: "24px", height: "24px" }}
                  />
                ) : (
                  t("myProfile.delete")
                )}
              </MyButton>
            </Box>
          </Box>
          <EditForm onSubmit={handleSubmit(onSubmit)}>
            <Box className="profile-form">
              <label>
                {t("email")}
                <input
                  {...register("email", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                  type="email"
                  className="profile-input"
                />
              </label>
              <div className="single-input">
                <label>{t("user.parol")}</label>
                <input
                  className="profile-input"
                  type={showPassword ? "text" : "password"}
                  placeholder={t("user.parol")}
                  {...register("password")}
                  autoComplete="off"
                />
                <div
                  className="eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <VisibilityIcon className="icon" />
                  ) : (
                    <VisibilityOffIcon className="icon" />
                  )}
                </div>
              </div>
              <label style={{ position: "relative" }}>
                {t("phoneNumber")}
                <input
                  {...register("phoneNumber", {
                    required: true,
                    maxLength: 13,
                  })}
                  autoComplete="off"
                  type="number"
                  className="profile-input"
                />
                {watch("phoneNumber")?.length >= 14 && (
                  <ErrorText
                    text={t("jsxErrorTexts.phoneNumberEnteredError")}
                  />
                )}
              </label>
              <div className="single-input">
                <label>{t("myProfile.repeatParol")}</label>
                <input
                  className="profile-input"
                  type={showPassword ? "text" : "password"}
                  placeholder={t("myProfile.repeatParol")}
                  {...register("confirmPassword")}
                  autoComplete="off"
                />
                {/* {errors?.password && <p className="error">!</p>} */}
                <div
                  className="eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <VisibilityIcon className="icon" />
                  ) : (
                    <VisibilityOffIcon className="icon" />
                  )}
                </div>
              </div>
            </Box>
            <Box className="profile-right">
              {isLoading ? (
                <MyButton>
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                    }}
                  />
                </MyButton>
              ) : (
                <button className="profile-submit" type="submit">
                  {t("myProfile.Update")}
                </button>
              )}
            </Box>
          </EditForm>
        </StyledBoxEdit>
      </div>
    </Wrapper>
  );
}

export default EditUserData;
