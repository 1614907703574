import React from "react";
import { Wrapper } from "./style";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import routeNames from "../../../constants/routeNames";
import {
  InformationFour,
  InformationOne,
  InformationThree,
  InformationTwo,
} from "../../../assets/images";
import { useTranslation } from "react-i18next";

function Information() {
  const { t } = useTranslation();
  return (
    <div>
      <Wrapper>
        <div className="container">
          <h2>{t("text.serviceMainText16")}</h2>
          <Box className="information-list">
            <Box className="information-item">
              <img
                src={InformationOne}
                className="information-item-img"
                alt="img"
              />
              <h4>{t("mainTexts.modul1Text")}</h4>
              <p>{t("mainTexts.modul1description")}</p>
              <Link
                className="information-link"
                to={routeNames?.ADMIN_MEETINGS}
              >
                {t("signIn")}
              </Link>
            </Box>
            <Box className="information-item">
              <img
                className="information-item-img"
                src={InformationTwo}
                alt="img"
              />
              <h4>{t("mainTexts.modul2Text")}</h4>
              <p>{t("mainTexts.modul2description")}</p>
              <Link className="information-link" to={routeNames?.ADMIN_USER}>
                {t("signIn")}
              </Link>
            </Box>
            <Box className="information-item">
              <img
                src={InformationThree}
                className="information-item-img"
                alt="img"
              />
              <h4>{t("mainTexts.modul3Text")}</h4>
              <p>{t("mainTexts.modul3description")}</p>
              <Link className="information-link" to={routeNames?.ADMIN_COMPANY}>
                {t("signIn")}
              </Link>
            </Box>
            <Box className="information-item">
              <img
                src={InformationFour}
                className="information-item-img"
                alt="img"
              />
              <h4>{t("mainTexts.modul4Text")}</h4>
              <p>{t("mainTexts.modul4description")}</p>
              <Link
                className="information-link"
                to={routeNames?.ADMIN_SECRETARY}
              >
                {t("signIn")}
              </Link>
            </Box>
          </Box>
        </div>
      </Wrapper>
    </div>
  );
}

export default Information;
