import { Box } from "@mui/material";
import styled from "styled-components";

export const StyleMeetingCard = styled(Box)`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  border-radius: 20px;
  margin-top: 21px;
  padding: 20px 30px;
  display: block;
  border: 2px solid #ddf8f0;
  background: #fff;
  transition: 0.3s;

  &:hover {
    border: 2px solid #208667;
  }

  h2 {
    color: #208667;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 0;
  }
  .meeting-list {
    display: flex;
    align-items: center;
    gap: 0 30px;
  }

  h4 {
    color: #121212;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 0;
  }

  h5 {
    color: #121212;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 0;
  }

  span {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .meeting-number {
    border-radius: 10px;
    border: 1px solid #208667;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 80px;
    color: #208667;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }

  .MuiButtonBase-root {
    border-radius: 8px;
    border: 1px solid #208667;
    background: #fff;
    color: #208667;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 8px 14px;
  }

  .button-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
`;

export const GridInner = styled.div`
  display: inline-block;
  margin-left: auto;
`;
