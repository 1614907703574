import { toast } from "react-toastify";
import { API } from "../../../../../../services/api";
import routeNames from "../../../../../../constants/routeNames";

export const updateMeetingFunc = async ({ data, navigation, t }) => {
  return await API.updateMeetingData(data)
    .then((res) => {
      toast.success(t("dataUpdated"));
      navigation(routeNames.ADMIN_MEETINGS);
    })
    .catch((err) => {
      console.log(err);
      if (
        err.response.data.title === "Company must have Chairmen or Secretary"
      ) {
        toast.error(t("mustHave"));
      } else if (err.message === "Request failed with status code 400") {
        toast.error(t("errorTime"));
      } else if (err.response.data.errorKey === "meetingExists") {
        toast.error(t("alreadyExist"));
      } else if (err.response.data.status === 500) {
        if (
          err.response.data.detail.includes(
            "Unable to find uz.depos.app.domain.User"
          )
        ) {
          toast.error(
            "Bu yig'ilish azosi o'chirib yuborilganligi sababli, yig'ilishni o'zgartirib bo'lmaydi."
          );
        }
      }
      console.log(err.response.data, t("sentError"));
    });
};

export const meetingGetCompaniesFunc = async () => {
  const response = await API.getAllCompanies()
    .then((res) => res.data)
    .catch((err) => console.log("ERROR", err));
  return response;
};

export const meetingGetCities = async () => {
  const response = await API.getCities()
    .then((res) => res.data)
    .catch((error) => console.log("Fetch Cities Error", error));
  return response;
};

export const meetingGetByIDFunc = async (payload) => {
  const response = await API.getMeeting({ meetingId: payload })
    .then((res) => {
      return res.data;
    })
    .catch((error) => console.log("Fetch Cities Error", error));
  return response;
};
