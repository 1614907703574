import styled from "styled-components";

export const SearchMeetingBox = styled.div`
  width: 100%;
  max-width: 631px;
  position: relative;

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`;

export const SearchMeetingListBox = styled.div`
  position: absolute;
  top: 34px;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
  background-color: #fff;
  box-shadow: -5px 10px 25px 0px rgba(0, 0, 0, 0.1);

  .search-list {
    max-width: 631px;
    max-height: 250px;
    overflow-y: auto;
    padding-bottom: 50px;
  }

  .data-error {
    padding-bottom: 25px;
  }
`;

export const SearchMeetingListInner = styled.div`
  position: relative;
  padding: 30px 16px;

  padding-bottom: ${(props) => (!props.top ? "75px" : "30px")};

  .search-item {
    position: relative;
    padding: 18px 0;
  }

  .search-item::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ddf8f0;
    content: "";
  }

  .search-item:last-child::after {
    display: none;
    content: ;
  }

  .search-list-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    /* top: ${(props) => (!props.top ? "75px" : "")}; */
    background-color: #ddf8f0;
    padding: 20px;
  }

  .search-list-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .form-date {
    color: #208667;
  }

  .form-date-label,
  .form-number-label {
    display: inline-block;
    max-width: 210px;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #208667;
    background: #fff;
  }

  .form-protocol-number {
    color: #208667;
  }

  .form-select {
    max-width: 150px;
    border-radius: 8px;
    border: 1px solid #208667;
    background: #fff;
    padding: 8px 10px;
    color: #208667;
  }

  .select-placeholder {
    color: #d4d4d4;
  }

  option {
    color: #208667;
  }
`;
