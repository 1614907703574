import { Link, useNavigate } from "react-router-dom";
import { StyledBox } from "./style";
import { useSelector } from "react-redux";

function CompCard({ data }) {
  const { name, description, meetingCount } = data;
  const auth = useSelector((store) => store.auth);

  let redirect = "";

  if (auth.user_role === "ROLE_ADMIN") {
    redirect = "/admin/comp/meetings#active";
  } else if (auth.user_role === "ROLE_USER") {
    redirect = "/user/comp/meetings#active";
  }

  return (
    <Link state={{ comp: data?.id }} to={redirect}>
      <StyledBox>
        <div className="left">
          <div className="info">
            <h2 className="comp_name">{name}</h2>
            <p className="comp_desc"></p>
          </div>
        </div>
        <div className="right">
          <div className="meet_count">{meetingCount}</div>
        </div>
      </StyledBox>
    </Link>
  );
}

export default CompCard;
