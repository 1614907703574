import { Box } from "@mui/material";
import React from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { HeaderBox, Wrapper } from "./style";
import { HeaderOne } from "../../assets/images";
import Nav from "../Nav";
import { LikeIcon, SafetyIcon, TimerIcon } from "../../assets/icons";
import Login from "../../pages/Login";
import { useTranslation } from "react-i18next";

const sliderData = (t) => {
  return [
    {
      id: 1,
      title: t("homeSliderText.text1"),
      img: HeaderOne,
      icon: SafetyIcon,
    },
    {
      id: 2,
      title: t("homeSliderText.text2"),
      img: HeaderOne,
      icon: LikeIcon,
    },
    {
      id: 3,
      title: t("homeSliderText.text3"),
      img: HeaderOne,
      icon: TimerIcon,
    },
  ];
};

const Header = () => {
  const { t } = useTranslation();
  return (
    <HeaderBox>
      <Box className="login-box">
        <Login />
      </Box>
      <Swiper
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {sliderData(t).map((slider) => (
          <SwiperSlide>
            <Box
              className="header-image"
              key={slider.id}
              sx={{ backgroundImage: `url(${slider.img})` }}
            >
              <Nav />
              <Wrapper>
                <Box className="header-list">
                  <h2>{slider.title}</h2>
                  <img className="header-icon" alt="Icon" src={slider.icon} />
                </Box>
              </Wrapper>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </HeaderBox>
  );
};

export default Header;
