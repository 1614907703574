import styled from "styled-components";

export const AgendaTable = styled.table`
  width: 100%;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
  padding: 15px;
  caption-side: bottom;
  border-collapse: collapse;
  margin-top: 30px;

  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.08);

  thead {
    background: #ddf8f0;
  }

  th {
    color: #208667;
    /* padding: 15px 47px 15px 0px; */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 20px;
  }

  tr {
    padding: 10px 2px;
    height: 53px;
  }

  .list-item {
    border-bottom: 1px solid #ddf8f0;
    cursor: pointer;
  }

  td {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px 2px;
  }

  .user-delete {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .menu-box {
    background: #fff;
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.08);
    display: inline-flex;
    padding: 8px 10px 7px 8px;
    justify-content: center;
    align-items: flex-start;
    margin-top: -30px;
    margin-left: 3px;
  }
`;
