import styled from "styled-components";
import { MIN_LENGTH } from "../../constants";
import { Box } from "@mui/material";

export const Wrapper = styled.div`
  max-width: ${MIN_LENGTH}px;
  margin: auto;
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1170px) {
    padding: 0 16px;
  }

  .header-list {
    text-align: left;
  }

  h2 {
    color: #fff;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    width: 780px;
    margin: 20px;

    @media only screen and (max-width: 1170px) {
      width: 780px;
      max-width: 600px;
      margin-left: 0px;
      font-size: 25px;
    }
  }

  .header-icon {
    margin-top: 20px;
  }
`;

export const HeaderBox = styled.div`
  .header-image {
    position: relative;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 650px;
    margin-top: 60px;
  }

  .login-box {
    position: absolute;
    top: 208px;
    left: calc(50% + 252px);
    z-index: 2;

    @media only screen and (max-width: 1170px) {
      top: 208px;
      left: inherit;
      right: 16px;
    }
  }
`;

export const BoxImage = styled(Box)`
  background-repeat: no-repeat;
`;
