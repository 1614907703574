import { EditBody, EditContainer } from "./style";
import MyButton from "../../../../components/UI/MyButton/MyButton";
import OneProtocol from "./OneProtocol";
import { useMutation, useQuery } from "react-query";
import { API } from "../../../../services/api";
import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AgendaContext } from "../../pages/MeetingDetails/context/MeetingDetailContext";
import { useTranslation } from "react-i18next";
import { editInputText } from "../../../../utils";

const mapingFunc = (agendas) => {
  const data = [];
  agendas.forEach((element) => {
    const obj = {
      agendaSubjectDTO: {
        agendaId: element.id,
        id: 0,
        meetingId: element.meetingId,
        subject: element.subject,
      },
      answerQuestionDTO: {
        answers: [
          {
            agendaId: element.id,
            answer: "",
            id: 0,
          },
        ],
        id: 0,
        meetingId: element.meetingId,
      },
      votingTextDTO: {
        agendaId: element.id,
        meetingId: element.meetingId,
        text: element.votingOptions[0].votingText,
      },
    };
    data.push(obj);
  });

  return data;
};

const getData = async (meetingId, setProtocolData, setLoading) =>
  API.getAgendaAnswers(meetingId)
    .then((res) => {
      if (!!res.data) {
        const newData = res.data[0];
        const resData = newData.answers.sort((a, b) => a.id - b.id);
        setProtocolData((prev) =>
          prev.map((item, idx) => ({
            ...item,
            answerQuestionDTO: {
              answers: [
                {
                  agendaId: resData[idx].agendaId,
                  answer: resData[idx].answer,
                  id: resData[idx].id,
                },
              ],
              id: newData.id,
              meetingId: newData.meetingId,
            },
          }))
        );
      }
      return res.data;
    })
    .catch((err) => console.log(err))
    .finally(() => setLoading(false));

const ProtocolEdit = ({ agendas, meetingId }) => {
  const { refetch } = useContext(AgendaContext);
  const [protocolData, setProtocolData] = useState(mapingFunc(agendas));
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    getData(meetingId, setProtocolData, setLoading);
  }, []);

  const { mutate, isLoading } = useMutation(
    async (data) =>
      await API.updateProtocol(data)
        .then((res) => {
          toast.success("successful");
          getData(meetingId, setProtocolData, setLoading);
          refetch();
        })
        .catch((err) => toast.error(t("userPage.protocol.protocolEditErr")))
  );

  if (loading || isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="80vh"
      >
        <CircularProgress
          color="success"
          style={{ width: "100px", height: "100px" }}
        />
      </Box>
    );
  }

  const handleClick = () => {
    const newProtocolData = JSON.parse(JSON.stringify(protocolData));

    newProtocolData.forEach((el) => {
      console.log(el.answerQuestionDTO.answers[0].answer);
      el.answerQuestionDTO.answers[0].answer = editInputText(
        el.answerQuestionDTO.answers[0].answer.replaceAll("<p>",'<p style="text-indent: 70px;padding: 0">').replaceAll("&nbsp;",'')
      );
    });
    mutate(newProtocolData);
  };

  return (
    <EditContainer>
      <EditBody>
        {protocolData?.map((item, idx) => {
          return (
            <OneProtocol
              protocol={item}
              setProtocolData={setProtocolData}
              idx={idx}
            />
          );
        })}
      </EditBody>
      <MyButton
        width="210px"
        background="#208667"
        color="#fff"
        onClick={handleClick}
      >
        {t("userPage.protocol.protocolEditBtn")}
      </MyButton>
    </EditContainer>
  );
};

export default ProtocolEdit;
