import styled from "styled-components";
import { MIN_LENGTH } from "../../../../../constants";

export const Wrapper = styled.div`
  background: var(--bgImage);

  .container {
    max-width: ${MIN_LENGTH}px;
    margin: auto;
    padding: 30px 0;
    padding-bottom: 50px;
  }

  .create-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
  }

  h5 {
    color: #208667;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  a {
    color: rgba(32, 134, 103, 0.5);
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  .form-submit {
    margin-top: 25px;
  }

  .create-form {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.08);
    padding: 20px;
  }

  label {
    display: flex;
    flex-direction: column;
    color: var(--text-drak, #000);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .form-input,
  .form-textarea {
    border-radius: 6px;
    border: 1px solid #208667;
    background: var(--interactive-enabled, #fff);
    padding: 5px 12px;
    outline: none;
    margin-top: 7px;
  }

  .form-input {
    height: 30px;
    /* width: 100%; */
    max-width: 100%;
  }

  .form-textarea {
    height: 100px;
    /* width: 100%; */
    max-width: 100%;
  }

  .create-select {
    border-radius: 6px;
    border: 1px solid #208667;
    background: var(--interactive-enabled, #fff);
    padding: 5px 12px;
    width: 100%;
    outline: none;
    height: 40px;
    margin-top: 7px;
  }

  .form-list {
    display: grid;
    grid-template-columns: minmax(150px, 520px) minmax(150px, 480px);
    align-items: start;
    justify-content: space-between;
    /* gap: 66px; */
    margin: 15px 0;
  }

  .form-selects {
    width: 100%;
    max-width: 520px;

    .form-select {
      width: 400px;

      @media only screen and (max-width: 1046px) {
        width: 360px;
      }
    }
  }

  .form-selects-in {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 520px;
  }
  .form-plus {
    border-radius: 8px;
    border: 1px solid #208667;
    background: #fff;
    width: 42px;
    height: 42px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    cursor: pointer;
  }

  .form-minus {
    border-radius: 8px;
    border: 1px solid #208667;
    background: #fff;
    width: 42px;
    height: 42px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    font-size: 25px;
    font-weight: bold;
    color: #208667;
    cursor: pointer;
  }

  .form-select {
    background: var(--interactive-enabled, #fff);
    width: 100%;
    max-width: 520px;
    outline: none;
    height: 40px;
    margin-top: 7px;

    .MuiAutocomplete-input {
      color: var(--text-drak, #000);
      font-family: "Inter", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .MuiAutocomplete-inputRoot {
      padding: 3px;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #208667;
    }

    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #208667 !important;
      border-width: 1px !important;
    }

    .MuiFormLabel-root {
      top: -5px;
    }

    .MuiFormLabel-root.Mui-focused {
      color: #208667;
      top: 0px;
    }
  }
`;
