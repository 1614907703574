import { useSelector } from "react-redux";
import CompCard from "../../components/CompCard";
import { CompaniesTitle, Wrapper } from "./style";
import { QUERIES_NAME } from "../../../../constants/apiqueries";
import { useQuery } from "react-query";
import { API } from "../../../../services/api";
import Loading from "../../../../components/Loading";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineCaretRight } from "react-icons/ai";

function Companies() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(true);
  const user = useSelector((state) => state.userData);
  const auth = useSelector((store) => store.auth);

  let basePath;

  if (auth.user_role === "ROLE_ADMIN") {
    basePath = "/admin";
  } else if (auth.user_role === "ROLE_USER") {
    basePath = "/user";
  }

  const { isLoading, isError, data, error } = useQuery(
    QUERIES_NAME.GET_COMPANIES,
    async () =>
      await API.getCompanies({ id: user.id })
        .then((res) => res.data)
        .catch((err) => console.log(err))
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <Typography sx={{ textAlign: "center" }} variant="h3">
        Nimadir noto'g'ri bajarildi
      </Typography>
    );
  }

  return (
    <Wrapper>
      <div className="container">
        {auth.user_role === "ROLE_ADMIN" ? (
          <Box className="create-top">
            <Link to={basePath} className="special">
              {t("home")}
            </Link>
            <AiOutlineCaretRight />
            <h5>{t("userPage.companies")}</h5>
          </Box>
        ) : null}
        <CompaniesTitle>{t("userPage.companies")}</CompaniesTitle>
        {data?.map((comp, index) => {
          if (comp?.active === tabValue)
            return <CompCard data={comp} key={index} />;
        })}
      </div>
    </Wrapper>
  );
}

export default Companies;
