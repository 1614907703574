import React from "react";
import { Wrapper } from "./style";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Nav = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <ul className="nav-list">
        <li>
          <Link to="/" className="nav-link">
            {t("home").toUpperCase()}
          </Link>
        </li>
        <li>
          <a href="https://navoiyuran.uz/" target="blank" className="nav-link">
            {t("website").toUpperCase()}
          </a>
        </li>
        <li>
          <a
            href="https://navoiyuran.uz/page/documents"
            target="blank"
            className="nav-link"
          >
            {t("documents").toUpperCase()}
          </a>
        </li>
        <li>
          <a href="#" className="nav-link">
            {t("tutorial").toUpperCase()}
          </a>
        </li>
      </ul>
    </Wrapper>
  );
};
export default Nav;
