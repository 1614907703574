import React, { useEffect, useState } from "react";
import AgendaForm from "../AgendaForm/AgendaForm";
import styled from "styled-components";
import { useMutation } from "react-query";
import { API } from "../../../../../../../../services/api";
import { MyButton } from "../../../../../../../../components";
import { toast } from "react-toastify";
import { Box, CircularProgress } from "@mui/material";
import { createMeetingAgendaFileFunc } from "../../extra";
import { useTranslation } from "react-i18next";

const initialState = {
  subject: "",
  speakerId: "",
  debateEnum: "",
  speakTimeEnum: "",
  issueInfo: "",
};

const getAgendaByIdFunc = async ({ id, setData, setAgendaIdLoading, t }) => {
  try {
    setAgendaIdLoading(true);
    return await API.getAgendaById(id).then((res) => {
      setData({
        ...res.data,
        subject: res.data.subject,
        speakerId: res.data.speakerId,
        debateEnum: res.data.debateEnum,
        speakTimeEnum: res.data.speakTimeEnum,
        issueInfo: res.data.votingOptions[0].votingText,
      });
      return res.data;
    });
  } catch (e) {
    console.log(t("notAgenda") + ": ", e);
  } finally {
    setAgendaIdLoading(false);
  }
};

const AgendaEditModal = ({ speakers, id, setOpen, open, getAgendaRefetch }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(initialState);
  const [agendaIdLoading, setAgendaIdLoading] = useState(true);
  const [issueFile, setIssueFile] = useState("");

  const { mutate: createAgendaFiles, isLoading: agendaFileLoading } =
    useMutation(async (fileData) => {
      return await API.createAgendaFile(fileData)
        .then((res) => {
          getAgendaRefetch();
          setOpen(false);
          toast.success(t("fileUploaded"));
        })
        .catch((err) => {
          console.log(t("fileNotUploaded"), err);
          toast.error(t("fileTryAgain"));
        })
        .finally(() => setIssueFile(""));
    });

  const {
    mutate,
    isLoading: agendaEditLoading,
    isSuccess,
  } = useMutation(async ({ data, setData }) => {
    return await API.updateAgendaData(data)
      .then((res) => {
        if (!!issueFile?.files) {
          const fileData = new FormData();
          for (let file of issueFile?.files) {
            fileData.append("agenda_file", file);
          }

          fileData.append("agendaId", id);
          createAgendaFiles(fileData);
        }
        if (!issueFile?.files) {
          setOpen(false);
        }

        setData({ ...initialState });
        toast.success(t("dataUpdated"));
        getAgendaRefetch();
      })
      .catch((err) => {
        toast.error(`${t("errorOccurred")} ${err}`);
        console.log(t("agendaNotUpdated"), err);
      });
  });

  const handleSubmit = (e) => {
    const meetingId = JSON.parse(localStorage.getItem("newMeetnigId")).id;
    const newAgenda = {
      active: true,
      debateEnum: data.debateEnum,
      meetingId,
      speakTimeEnum: data.speakTimeEnum,
      speakerId: data.speakerId,
      speakerStatus: true,
      subject: data.subject,
      typeEnum: "FULL",
      id: data.id,
      extraInfo: "",
      isActive: true,
      answerOptions: data.answerOptions,
      votingOptions: [
        {
          ...data.votingOptions[0],
          votingText: data.issueInfo,
        },
      ],
    };

    e.preventDefault();

    mutate({
      data: newAgenda,
      setData,
    });
  };

  const handleClose = (evt) => {
    if (evt.target.matches(".agenda-edit-box")) {
      setOpen(false);
    }
  };

  useEffect(() => {
    getAgendaByIdFunc({ id, setData, setAgendaIdLoading, t });
  }, [id]);

  return (
    <div>
      <AgendaEditBox
        active={open}
        onClick={handleClose}
        className="agenda-edit-box"
      >
        <AgendaEditBoxInner>
          {agendaIdLoading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={"400px"}
              width={"400px"}
            >
              <CircularProgress
                color="success"
                style={{ width: "100px", height: "100px" }}
              />
            </Box>
          ) : (
            <>
              <AgendaForm
                data={data}
                setData={setData}
                speakers={speakers}
                setIssueFile={setIssueFile}
                issueFile={issueFile}
                isSuccess={isSuccess}
              />
              <div className="form-box-footer">
                <MyButton
                  color="#208667"
                  background="#FFFFFF"
                  border="#208667"
                  onClick={() => setOpen(false)}
                >
                  {t("back")}
                </MyButton>

                {agendaEditLoading || agendaFileLoading ? (
                  <MyButton color="#FFFFFF" border="#208667">
                    <CircularProgress
                      color="success"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </MyButton>
                ) : (
                  <MyButton
                    color="#FFFFFF"
                    background="#208667"
                    border="#208667"
                    type="submit"
                    form="agendaForm"
                    onClick={handleSubmit}
                  >
                    {t("user.sozdat")}
                  </MyButton>
                )}
              </div>
            </>
          )}
        </AgendaEditBoxInner>
      </AgendaEditBox>
    </div>
  );
};

export default AgendaEditModal;

const AgendaEditBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: ${(props) => (props.active ? "block" : "none")};
`;

const AgendaEditBoxInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 526px;
  text-align: left;
  overflow-y: auto;
  background-color: #f6fcff;

  .form-box-footer {
    padding: 0 20px;
    padding-bottom: 20px;
  }
`;
