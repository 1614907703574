import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const AssemblyData = ({ meeting }) => {
  const lan = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();

  return (
    <AssemblyDataBox>
      <h3 className="assembly-data-title">{t("meetingData")}</h3>

      <ul className="assembly-data-list">
        <li className="assembly-data-item">
          <span className="assembly-data-item-text1">{t("idProtocol")}</span>
          <p className="assembly-data-item-text2">{meeting?.protocolNumber}</p>
        </li>

        <li className="assembly-data-item">
          <span className="assembly-data-item-text1">{t("startMeeting")}</span>
          <p className="assembly-data-item-text2">
            {meeting?.startDate.slice(0, 10)}
          </p>
        </li>

        <li className="assembly-data-item">
          <span className="assembly-data-item-text1">
            {t("meetingAddress")}
          </span>
          <p className="assembly-data-item-text2">
            {meeting?.city[`name${lan.replace(lan[0], lan[0].toUpperCase())}`]}
          </p>
        </li>

        <li className="assembly-data-item">
          <span className="assembly-data-item-text1">{t("time")}</span>
          <p className="assembly-data-item-text2">
            {new Date(meeting?.startDate).toLocaleTimeString()}
          </p>
        </li>

        <li className="assembly-data-item">
          <span className="assembly-data-item-text1">
            {t("meetingsList.address")}
          </span>
          <p className="assembly-data-item-text2">{meeting?.description}</p>
        </li>

        <li className="assembly-data-item">
          <span className="assembly-data-item-text1">
            {t("meetingsList.startRegister")}
          </span>
          <p className="assembly-data-item-text2">
            {new Date(meeting?.startRegistration).toLocaleString()}
          </p>
        </li>

        <li className="assembly-data-item">
          <span className="assembly-data-item-text1">
            {t("meetingsList.finishRegister")}
          </span>
          <p className="assembly-data-item-text2">
            {new Date(meeting?.endRegistration).toLocaleString()}
          </p>
        </li>
      </ul>
      <p className="text-box">
        <span className="assembly-data-item-text1">
          {" "}
          {t("meetingsList.commentMeeting")}
        </span>
        <span className="assembly-data-item-text2">{meeting?.description}</span>
      </p>
    </AssemblyDataBox>
  );
};

export default AssemblyData;

const AssemblyDataBox = styled.div`
  padding: 20px 0 20px 0 !important;
  border-top: 2px solid #ddf8f0;
  border-bottom: 2px solid #ddf8f0;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  .assembly-data-title {
    margin-bottom: 20px !important;
    font-size: 22px;
  }

  .assembly-data-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 332px));
    column-gap: 62px;
    row-gap: 15px;
  }

  .text-box {
    margin-top: 15px;
  }

  .assembly-data-item-text1,
  .assembly-data-item-text2 {
    display: block;
  }

  .assembly-data-item-text1 {
    color: #5d6b8a;
    font-size: 14px;
    letter-spacing: 0.5px;
  }
`;
