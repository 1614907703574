import { Wrapper } from "./style";
import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { API } from "../../../../services/api";
import { dispatch } from "../../../../redux";
import FaceID from "../FaceId";
import { useTranslation } from "react-i18next";

function UserNameLogin() {
  const [showPassword, setShowPassword] = useState(false);
  const [showFaceID, setShowFaceID] = useState(false);
  const { id } = useSelector((store) => store.userData);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onFaceIdClick = () => {
    setShowFaceID(true);

  };

  const { mutate } = useMutation(async (payload) => {
    await API.login(payload)
      .then((res) => {
        localStorage.setItem("token", `Bearer ${res.data?.id_token}`);
        dispatch.auth.login(res.data);
      })
      .catch((error) => {
        console.log("Auth dispatch  error", error);
        toast.error(t("user.loginErr"));
      });
    await API.getUserData()
      .then((res) => {
        // console.log('User data', res.data?.faceIdRequired);
        dispatch.userData.userData(res.data);

        if (res.data?.faceIdRequired) {
          onFaceIdClick();
        } else {
          dispatch.auth.auth();
        }
      })
      .catch((error) => console.log("Auth dispatch  error", error));
  });

  const onSubmit = (data) => {
    mutate({ ...data, rememberMe: true });
  };
  const onPasswordToggleClick = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Wrapper>
      {showFaceID && (
        <FaceID onCloseClick={() => setShowFaceID(false)} id={id} />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-item">
          <input
            placeholder={t("user.login")}
            className="form-input"
            type="login"
            required
            {...register("username", { required: true })}
          />
        </div>
        <div className="form-item">
          <input
            placeholder={t("password")}
            className="form-input"
            maxLength={17}
            type={showPassword ? "text" : "password"}
            {...register("password", { required: true })}
            required
          />
          <div className="eye-icon" onClick={onPasswordToggleClick}>
            {showPassword ? (
              <VisibilityIcon className="icon" />
            ) : (
              <VisibilityOffIcon className="icon" />
            )}
          </div>
        </div>
        <button type="submit" className="form-button">
          {t("signIn")}
        </button>
      </form>
    </Wrapper>
  );
}
export default UserNameLogin;
