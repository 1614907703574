import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Navbar from "../components/Navbar";
import PageNotFound from "./components/PageNotFound";
import PrivateRoute from "./components/PrivateRoute";
import { PrivateRoutes, PublicRoutes } from "./routes";
import NewHeader from "../components/NewHeader";
import Footer from "../components/Footer";

function AppRoutes() {
  const { isLogged, user_role } = useSelector((store) => store.auth);

  const Nav = isLogged && !!user_role ? NewHeader : Navbar;

  return (
    <>
      <Nav />
      <Routes>
        {PublicRoutes?.map((route, idx) => (
          <Route
            key={route.path + idx}
            path={route.path}
            element={route.element}
          />
        ))}
        {PrivateRoutes?.map((route, idx) => {
          return (
            <Route
              key={route?.path + idx}
              path={route.path}
              element={
                <PrivateRoute path={route.path}>{route?.element}</PrivateRoute>
              }
            />
          );
        })}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default AppRoutes;
