import { DescTextarea, SingleProtocol, TitleInput } from "./style";
import { Typography } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useTranslation } from "react-i18next";
import { editInputText } from "../../../../utils";

const OneProtocol = ({ protocol, setProtocolData, idx }) => {
  const { t } = useTranslation();
  const handleChange = ({ agenda, key, value }) => {
    setProtocolData((prev) =>
      prev.map((el) => {
        if (el?.agendaSubjectDTO?.agendaId === agenda) {
          let item = JSON.parse(JSON.stringify(el));
          if (key === "subject") {
            item.agendaSubjectDTO[key] = editInputText(value);
          } else if (key === "desc") {
            item.votingTextDTO.text = editInputText(value);
          } else {
            item.answerQuestionDTO.answers[0].answer = value;
          }

          return item;
        }
        return el;
      })
    );
  };

  return (
    <SingleProtocol
      className="editor"
      key={protocol.agendaSubjectDTO?.agendaId}
    >
      <Typography sx={{ marginBottom: 1, fontSize: "20px" }} component="h2">
        {idx + 1} - {t("agenda")}
      </Typography>

      <Typography sx={{ marginBottom: 1 }}>
        {t("meetingCreated.filePage.agenda")}
      </Typography>
      <TitleInput
        value={protocol.agendaSubjectDTO.subject}
        onChange={(e) =>
          handleChange({
            agenda: protocol.agendaSubjectDTO?.agendaId,
            key: "subject",
            value: e.target.value,
          })
        }
      />
      <Typography sx={{ marginTop: 2, marginBottom: 1 }}>
        {t("userPage.protocol.agendaInfo")}
      </Typography>
      <DescTextarea
        rows="5"
        value={protocol.votingTextDTO.text}
        onChange={(e) =>
          handleChange({
            agenda: protocol.agendaSubjectDTO?.agendaId,
            key: "desc",
            value: e.target.value,
          })
        }
      />
      <Typography sx={{ marginTop: 2, marginBottom: 1 }}>
        {protocol?.subject}
      </Typography>
      <CKEditor
        editor={ClassicEditor}
        data={protocol.answerQuestionDTO.answers[0].answer}
        config={{ removePlugins: ["Autoformat"] }}
        onReady={(editor) => {
          console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          console.log(data);
          handleChange({
            agenda: protocol.agendaSubjectDTO?.agendaId,
            key: "answer",
            value: data,
          });
        }}
      />
    </SingleProtocol>
  );
};

export default OneProtocol;
