import React, { useState } from "react";
import { StyledBox, Wrapper } from "./style";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import routeNames from "../../constants/routeNames";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LikeIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";
import { dispatch } from "../../redux";

const MyProfile = () => {
  const { isLogged, user_role } = useSelector((store) => store.auth);
  const userData = useSelector((store) => store.userData);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: userData?.email,
    phoneNumber: userData?.phoneNumber || "",
    password: "",
    confirmPassword: "",
  });
  const { t } = useTranslation();

  const redirect =
    isLogged && user_role === "ROLE_ADMIN"
      ? routeNames.ADMIN
      : routeNames.USER_COMPANIES;

  const onLogoutClick = () => {
    dispatch.auth.logoutAsync();
    navigate(routeNames.LOGIN);
  };

  return (
    <Wrapper>
      <div className="container">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="medium" color="disabled" />}
          aria-label="breadcrumb"
          className="breadcrumbs"
        >
          <Link
            underline="hover"
            key="1"
            color="inherit"
            to={redirect}
            className="link"
          >
            {t("routes.menu")}
          </Link>

          <Typography className="profile" key="2" variant="h6" color="primary">
            {t("routes.profile")}
          </Typography>
        </Breadcrumbs>
        <StyledBox>
          <Box className="profile-list">
            {!!userData?.imageUrl ? (
              <img src={userData?.imageUrl} alt="LikeIcon" />
            ) : (
              <img src={LikeIcon} alt="LikeIcon" />
            )}
            <Box>
              <h3>{userData?.fullName}</h3>
              <p>
                {t("myProfile.position")}
                <b>
                  {isLogged && user_role === "ROLE_ADMIN"
                    ? t("meetingCreated.roles.secretary")
                    : t("user.user")}
                </b>
              </p>
            </Box>
          </Box>
          <Box className="profile-form">
            <label>
              {t("email")}
              <input
                value={formData?.email}
                type="email"
                className="profile-input"
              />
            </label>
            <label>
              {t("phoneNumber")}
              <input
                value={formData?.phoneNumber}
                autoComplete="off"
                type="tel"
                className="profile-input"
              />
            </label>

            <div className="single-input">
              <label>{t("user.parol")}</label>
              <input
                className="profile-input"
                type={showPassword ? "text" : "password"}
                placeholder={t("user.parol")}
                autoComplete="off"
                value={formData?.password}
              />
              <div
                className="eye-icon"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <VisibilityIcon className="icon" />
                ) : (
                  <VisibilityOffIcon className="icon" />
                )}
              </div>
            </div>
          </Box>
          <Box className="profile-right">
            <button
              onClick={() => navigate(routeNames.EDIT_PROFILE)}
              className="profile-submit"
            >
              {t("user.redaktorovat")}
            </button>
            <button className="profile-close" onClick={onLogoutClick}>
              {t("exit")}
            </button>
          </Box>
        </StyledBox>
      </div>
    </Wrapper>
  );
};

export default MyProfile;
