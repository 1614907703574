import styled from "styled-components";
import { Box } from "@mui/material";

export const HeaderPage = styled(Box)`
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 2px 45px 0px rgba(0, 0, 0, 0.08);
  width: 317px;
  height: 444px;
  flex-shrink: 0;

  p {
    color: #868485;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    text-align: center;
  }

  button {
    border-radius: 8px;
    border: 1px solid #208667;
    background: #fff;
    width: 120px;
    height: 40px;
    padding: 8px 20px;
    color: #208667;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0 15px;
    cursor: pointer;
  }

  select {
    border-radius: 8px;
    background: #f0f0f0;
    padding: 5px 23px;
    border: none;
    outline: none;
    height: 45px;
    font-size: 16px;
    width: 280px;
    gap: 10px;
    margin-top: 20px;
  }
`;

export const Img = styled.img`
  width: 200px;
  margin: 35px;
`;
