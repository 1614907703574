import React, { useEffect, useRef } from "react";
import {
  InputFile,
  MyInput,
  MySelect,
  MyTextarea,
} from "../../../../../../../../components";
import { AgendaFormBox } from "./AgendaFormStyle";
import { useTranslation } from "react-i18next";
import { editInputText } from "../../../../../../../../utils";

const fileListArr = (fileList, arr) => {
  for (let i = 0; i < fileList?.files?.length; i++) {
    arr.push(fileList.files[i]);
  }
  return arr;
};

const AgendaForm = ({
  data,
  setData,
  speakers,
  setIssueFile,
  issueFile,
  loading,
  isSuccess,
}) => {
  const { t } = useTranslation();
  const inputFileRef = useRef(null);

  const agendaEnumTimes = [
    { id: 1, label: t("pages.agenda.minute2"), type: "TWOMIN" },
    { id: 2, label: t("pages.agenda.minute5"), type: "FIVEMIN" },
    { id: 3, label: t("pages.agenda.minute10"), type: "TENMIN" },
    { id: 4, label: t("pages.agenda.minute15"), type: "FIFTEENMIN" },
    { id: 5, label: t("pages.agenda.minute20"), type: "TWENTYMIN" },
  ];

  const handleChange = ({ key, value }) => {
    const newData = {
      ...data,
      [key]: value,
    };
    setData(newData);
  };

  let arr = [];
  if (issueFile?.files?.length) {
    arr = fileListArr(issueFile, arr);
  }

  useEffect(() => {
    if (isSuccess) {
      inputFileRef.current.value = null;
    }
  }, [isSuccess]);

  return (
    <AgendaFormBox>
      <form id="agendaForm">
        <div className="form-col">
          <label htmlFor="" className="form-label">
            <span>{t("meetingCreated.filePage.agenda")}</span>
          </label>
          <MyInput
            placeholder={t("companyForm.enter")}
            value={data.subject}
            name="subject"
            required={true}
            onChange={(e) =>
              handleChange({
                key: "subject",
                value: editInputText(e.target.value),
              })
            }
          />
        </div>

        <div className="form-grid">
          <div className="form-col">
            <label htmlFor="" className="form-label">
              <span>{t("meetingCreated.roles.speaker")}</span>
            </label>
            <MySelect
              placeholder={t("chooseSpeaker")}
              value={
                !!data.speakerId
                  ? speakers.find((el) => el.id === data.speakerId)?.label
                  : ""
              }
              data={speakers}
              onChange={(value, option) => {
                handleChange({ key: "speakerId", value: option?.id });
              }}
              loading={loading}
              maxwidth="319px"
              required={true}
            />
          </div>

          <div className="form-col">
            <label htmlFor="" className="form-label">
              <span>{t("meetingCreated.waitTime")}</span>
            </label>
            <MySelect
              placeholder={t("meetingCreated.placeholders.selectDebut")}
              value={
                data.debateEnum
                  ? agendaEnumTimes.find((el) => el.type === data.debateEnum)
                      ?.label
                  : null
              }
              data={agendaEnumTimes}
              onChange={(value, option) =>
                handleChange({ key: "debateEnum", value: option?.type })
              }
              maxwidth="319"
              required={true}
            />
          </div>

          <div className="form-col">
            <label htmlFor="" className="form-label">
              <span>{t("regulationTime")}</span>
            </label>
            <MySelect
              placeholder={t("chooseRegulationTime")}
              value={
                data.speakTimeEnum
                  ? agendaEnumTimes.find((el) => el.type === data.speakTimeEnum)
                      ?.label
                  : null
              }
              data={agendaEnumTimes}
              onChange={(value, option) =>
                handleChange({ key: "speakTimeEnum", value: option?.type })
              }
              maxwidth="319"
              required={true}
            />
          </div>
        </div>

        <div className="form-col">
          <label htmlFor="" className="form-label">
            <span>{t("aboutIssue")}</span>
          </label>
          <MyTextarea
            placeholder={t("companyForm.enter")}
            value={data.issueInfo}
            name="issueInfo"
            onChange={(e) =>
              handleChange({
                key: "issueInfo",
                value: editInputText(e.target.value),
              })
            }
            rows="4"
            required={true}
          />
        </div>

        <div className="form-col">
          <label htmlFor="" className="form-label">
            <span>{t("agendaFile")}</span>
          </label>
          <InputFile
            value={""}
            name="issueFile"
            changeValue={(val) => setIssueFile(val)}
            required={true}
            ref={inputFileRef}
          />
          {!!arr.length && (
            <p>
              {arr.map((el, idx) => (
                <span>
                  <b>{" " + (idx + 1) + ")"}</b>
                  {" " + el.name},
                </span>
              ))}
            </p>
          )}
        </div>
      </form>
    </AgendaFormBox>
  );
};

export default AgendaForm;
