import styled from "styled-components";
import { MIN_LENGTH } from "../../../../constants";

export const ArchiveMeetingsMainDiv = styled.div`
  padding-bottom: 50px;
  background: var(--bgImage);
`;

export const ArchiveMeetingsContainer = styled.div`
  max-width: ${MIN_LENGTH}px;
  margin: auto;
  padding-top: 30px;
`;

export const ArchiveMeetingsTitle = styled.h2`
  color: #208667;
`;

export const ArchiveMeetingsMeetingBody = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 550px) minmax(200px, 550px);
  padding: 32px 30px;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 2px 45px 0px rgba(0, 0, 0, 0.08);
`;
