import styled from "styled-components";
import bgImg from "../../../../assets/images/kotib/2.png";

const StyledProtocol = styled.div`
  * {
    list-style: inherit;
  }

  margin-bottom: 8px;

  .ck-editor__editable {
    height: 200px;
  }

  .input-group {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid grey;
    padding: 0;
    border-radius: 10px;
    input {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding: 4px 8px;
      width: 100%;
      border: none;
      outline: none;
      font-size: 18px;
      font-weight: 500;
    }
    button {
      padding: 8px;
      border: none;
      outline: none;
      cursor: pointer;
      background: #133b88;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .editor {
    margin-bottom: 12px;
  }
`;

const ProtocolsBody = styled.div`
  height: 350px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 20px;
  overflow-y: auto;
`;

const StyledProtocols = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .protos {
    display: flex;
    flex-direction: column;
  }
  .actions {
    margin: 10px 0;
    display: flex;
    justify-content: end;
  }
`;

const StyledUProtocol = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;
`;

const EditContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 25px;
  padding: 30px;
  border-radius: 12px;
  background-image: url(${bgImg});
  background-position: top 0 right 0;
  background-repeat: no-repeat;
  background-size: 250px;
  background-color: #f6fcff;

  .MuiTypography-root {
    text-align: left !important;
  }
`;

const EditBody = styled.div`
  width: 97%;
  height: 300px;
  margin-bottom: 20px;
  padding-right: 20px;
  overflow-y: auto;
`;

const SingleProtocol = styled.div`
  * {
    list-style: inherit;
  }

  padding-bottom: 20px;
  border-bottom: 2px solid #fff;

  &:last-child {
    border: none;
  }
`;

const TitleInput = styled.input`
  width: 95%;
  padding: 10px 12px;
  border-radius: 6px;
  border: 1px solid #208667;
  background: #fff;
  outline: none;
`;

const DescTextarea = styled.textarea`
  width: 95%;
  padding: 10px 12px;
  border-radius: 6px;
  border: 1px solid #208667;
  background: #fff;
  outline: none;
`;

export {
  StyledProtocol,
  ProtocolsBody,
  StyledProtocols,
  StyledUProtocol,
  EditContainer,
  EditBody,
  SingleProtocol,
  TitleInput,
  DescTextarea,
};
