import {useEffect, useState} from "react";
import {JitsiMeeting} from "@jitsi/react-sdk";
import {ShowButton, StartMeeting} from "./style";
import video from "../../../../assets/images/video.png";
import {useTranslation} from "react-i18next";
import axios from "axios";

const VideoMeeting = ({activeVideo, meeting, userRole, userData}) => {
    const [showVideo, setShowVideo] = useState(false);

    const {t} = useTranslation();
    useEffect(() => {
        navigator.permissions.query({name: 'camera'})
            .then((permissionObj) => {
                navigator.mediaDevices.getUserMedia({video: true})
                    .then(res => console.log(res))
                    .catch(handleDeny => console.log(handleDeny));
                console.log(permissionObj.state);
            })
            .catch((error) => {
                console.log('Got error :', error);
                alert('Got error :' + error);
            })
        navigator.getUserMedia({
            video: {
                facingMode: "user"
            }
        }, function (stream) {
            console.log(stream)
        }, err => console.log(err));


    }, []);
    return (
        <>
            <StartMeeting style={{display: !showVideo ? "flex" : "none"}}>
                <img src={video} alt="video"/>
                <ShowButton onClick={() => {
                    window.open("https://jitsi1.navoiyuran.uz/"+"Konferensiya" + meeting.id+"#userInfo.displayName=%22"+userData.fullName+"%22", "_blank");
                }}>
                    {t("userPage.startVideoConference")}
                </ShowButton>
            </StartMeeting>
            {showVideo && activeVideo ? <div style={{display: showVideo && activeVideo ? "block" : "none"}}>
                <JitsiMeeting
                    domain={ "jitsi1.navoiyuran.uz"}
                    roomName={"Konferensiya" + meeting.id}
                    configOverwrite={{
                        startWithAudioMuted: true,
                        disableModeratorIndicator: true,
                        startScreenSharing: true,
                        enableEmailInStats: false,
                    }}
                    interfaceConfigOverwrite={{
                        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                    }}
                    userInfo={{
                        displayName: userData.fullName,
                    }}
                    onApiReady={(externalApi) => {
                        console.log(externalApi, "externalApi")
                        externalApi.executeCommand('startRecording', {
                            mode: "file"
                        })
                        externalApi.executeCommand('sendChatMessage', {
                            message: "alo", //the text message
                            to: "", // the receiving participant ID or empty string/undefined for group chat.
                            ignorePrivacy: false // true if the privacy notification should be ignored. Defaulted to false.
                        });
                        // here you can attach custom event listeners to the Jitsi Meet External API
                        // you can also store it locally to execute commands
                    }}
                    onReadyToClose={() => {
                        setShowVideo(false);
                    }}
                    getIFrameRef={(iframeRef) => {

                        iframeRef.setAttribute("allow", "camera; microphone")
                        iframeRef.onError = iframeRef.onerror = (e) => {
                            console.log("error")
                            console.log(e)
                        }
                        iframeRef.oninvalid = (e) => {
                            console.log("oninvalid")
                            console.log(e)
                        }
                        iframeRef.onload = (e) => console.log(e, "load")
                        iframeRef.style.height = "500px";
                        iframeRef.style.width = "100%";
                    }}
                />
            </div> : ""}
        </>
    );
};

export default VideoMeeting;
