import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { PageNotFoundImage } from "../../assets/images";
import { MAX_LENGTH } from "../../constants";

const PageNotFound = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="90vh">
      {/* <Img src={PageNotFoundImage} alt="" /> */}
    </Box>
  );
};

export default PageNotFound;

const Img = styled.img`
  max-width: ${MAX_LENGTH}px;
  height: 80%;
`;
const Text = styled.h1`
  color: #000;
  font-size: 70px;
`;
