import styled from "styled-components";

export const FormBox = styled.div`
  * {
    box-sizing: border-box;
  }
  .box {
    display: grid;
    grid-template-columns: 377px 300px;
    column-gap: 22px;
  }

  .box-left-header {
    display: grid;
    grid-template-columns: 67px 134px 92px;
    align-items: center;
    border: 1px solid #ddf8f0;
    color: #208667;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: #ddf8f0;
    margin-bottom: 30px;
    padding: 10px 16px;
    padding-right: 60px;
    border-radius: 8px;
  }

  .box-left-header-item {
    padding: 12px;
    text-align: center;
  }

  .form-box-footer {
    display: flex;
    justify-content: flex-end;
    gap: 30px;
  }
`;
