import styled from "styled-components";
import { MIN_LENGTH } from "../../constants";
import { Box } from "@mui/material";

export const StyledBox = styled(Box)`
  border-radius: 0px 0px 20px 20px;
  background: #fff;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.15);
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 1001;
`;

export const Wrapper = styled.div`
  max-width: ${MIN_LENGTH}px;
  margin: auto;

  @media only screen and (max-width: 1170px) {
    padding: 0 16px;
  }

  .navbar-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
  }
`;

export const Img = styled.img`
  width: 200px;
`;
