import { toast } from "react-toastify";
import { API } from "../../../../../../../services/api";

export const meetingCreateFunc = async ({
  props,
  setActive,
  navigation,
  setMeeting,
  t,
}) => {
  return await API.createNewMeeting(props)
    .then((res) => {
      toast.success(t("adminToast.infoSuccess"));
      localStorage.setItem("newMeetnigId", JSON.stringify(res.data));
      setMeeting(res.data);
      setActive("participant");
      navigation(`/admin/meetings/create#participant`);
    })
    .catch((err) => {
      if (
        err.response.data.title === "Company must have Chairmen or Secretary"
      ) {
        toast.error(t("mustHave"));
      } else if (
        err.response.data.title === "Meeting with start date already created!"
      ) {
        toast.error(t("alreadyExist"));
      } else if (err.message === "Request failed with status code 400") {
        toast.error(t("errorTime"));
      } else if (err.response.data.errorKey === "meetingExists") {
        toast.error(t("alreadyExist"));
      } else {
        toast.error(t("errorOccurred"));
      }
      console.log(err.response.data, t("sentError"));
    });
};

export const updateMeetingFunc = async ({
  data,
  setActive,
  navigation,
  setMeeting,
  t,
}) => {
  return await API.updateMeetingData(data)
    .then((res) => {
      setMeeting(res.data);
      toast.success(t("adminToast.infoEditSuccessful"));
      localStorage.setItem("newMeetnigId", JSON.stringify(res.data));
      setActive("participant");
      navigation(`/admin/meetings/create#participant`);
    })
    .catch((err) => {
      if (
        err.response.data.title === "Company must have Chairmen or Secretary"
      ) {
        toast.error(t("mustHave"));
      } else if (err.message === "Request failed with status code 400") {
        toast.error(t("errorTime"));
      } else if (err.response.data.errorKey === "meetingExists") {
        toast.error(t("alreadyExist"));
      }
      console.log(err.response.data, t("sentError"));
    });
};

export const meetingGetCompaniesFunc = async () => {
  const response = await API.getCompaniesData()
    .then((res) => res.data)
    .catch((err) => console.log("EROOR", err));
  return response;
};

export const meetingGetCities = async () => {
  const response = await API.getCities()
    .then((res) => res.data)
    .catch((error) => console.log("Fetch Cities Error", error));
  return response;
};

export const meetingGetByIDFunc = async (payload) => {
  const response = await API.getMeeting({ meetingId: payload?.id })
    .then((res) => {
      return res.data;
    })
    .catch((error) => console.log("Fetch Cities Error", error));
  return response;
};
