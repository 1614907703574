import styled from "styled-components";
import { MIN_LENGTH } from "../../constants";

export const Wrapper = styled.div`
  background: var(--bgImage);
  padding-bottom: 50px;

  .container {
    max-width: ${MIN_LENGTH}px;
    margin: auto;
    padding: 0 40px;
  }

  .breadcrumbs {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .link {
    color: rgba(32, 134, 103, 0.5);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  .profile {
    color: #208667;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }
`;

export const StyledBox = styled.div`
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 2px 45px 0px rgba(0, 0, 0, 0.08);
  padding: 30px;
  margin-top: 40px;

  .profile-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }

  .profile-submit {
    border-radius: 8px;
    border: 1px solid #208667;
    background: #208667;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    width: 170px;
    height: 50px;
    cursor: pointer;
  }

  .profile-close {
    border-radius: 8px;
    border: 1px solid var(--red, #eb5757);
    height: 50px;
    cursor: pointer;
    width: 120px;
    background-color: transparent;
    color: var(--red, #eb5757);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
  }

  .profile-list {
    display: flex;
  }

  img {
    width: 120px;
    height: 120px;
    border-radius: 65px;
  }

  h3 {
    color: #10131b;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
    margin-left: 25px;
  }

  p {
    margin-top: 0;
    color: rgba(32, 134, 103, 0.8);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
    margin-left: 25px;
    margin-top: 10px;
  }

  b {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
    margin-left: 25px;
  }

  .profile-form {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 30px;
  }

  .single-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;
  }

  .eye-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  label {
    display: flex;
    flex-direction: column;
  }

  .profile-input {
    border-radius: 6px;
    border: 1px solid rgba(32, 134, 103, 0.25);
    background: var(--interactive-enabled, #fff);
    padding: 8px 15px;
    outline: none;
    height: 30px;
    margin-top: 8px;
    width: 500px;
  }
`;
