import { MIN_LENGTH } from "../../../../../constants";
import styled from "styled-components";

export const Wrapper = styled.div`
  background-image: var(--bgImage);

  .container {
    max-width: ${MIN_LENGTH}px;
    margin: auto;
    padding: 30px 0;
    padding-bottom: 50px;
  }

  .user-link {
    color: rgba(32, 134, 103, 0.5);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  h5 {
    color: #208667;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    margin-top: 0;
  }
  .user-top {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 15px;
  }

  .company-list {
    display: grid;
    grid-template-columns: 270px minmax(500px, 756px);
    justify-content: space-between;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.08);
    padding: 30px;
  }

  .company-left {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 12px 15px;
    width: 250px;
    gap: 1px;
    height: 110px;
  }

  .company-link {
    display: block;
    width: 100%;
    padding: 12px 15px;
    color: rgba(93, 133, 121, 0.5);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: #ddf8f0;
    border: none;
    cursor: pointer;
    text-align: start;
  }

  .active {
    position: relative;
    color: #5d8579;
  }

  .active::after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    border-radius: 8px 0px;
    background: #208667;
    content: "";
  }
`;
