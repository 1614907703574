import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../../../services/api";
import { useQuery } from "react-query";
import { GridInner, StyleMeetingCard } from "./style";
import { Box, Button, Grid, LinearProgress } from "@mui/material";
import { ROLES } from "../../../../constants";
import { QUERIES_NAME } from "../../../../constants/apiqueries";
import { useTranslation } from "react-i18next";

function MeetingCard({ meetingData }) {
  const user = useSelector((state) => state.userData);
  const { isLogged, user_role } = useSelector((store) => store.auth);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToMeetingDetail = (role) => {
    localStorage.setItem(
      "memberRole",
      JSON.stringify({
        meetingId: meetingData.id,
        ...role,
      })
    );
    if (isLogged && user_role === "ROLE_ADMIN") {
      navigate(`/admin/meeting-details/${meetingData.id}`, {
        state: { status: "active" },
      });
    } else {
      navigate(`/user/meeting-details/${meetingData.id}`, {
        state: { status: "active" },
      });
    }
  };

  const {
    data: userRole,
    loading,
    error,
  } = useQuery(
    [QUERIES_NAME.GET_MEMBER_ROLE_BY_MEETING, meetingData.id],
    async () =>
      API.getMemberRoleByMeeting({ userId: user.id, meetingId: meetingData.id })
  );

  function getDate(currentDate) {
    return (
      <>
        <p>
          {currentDate.substr(currentDate - 1, 10)} {currentDate.substr(11, 5)}
        </p>
      </>
    );
  }

  return (
    <StyleMeetingCard>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <div className="meeting-list">
            <div className="meeting-number">{meetingData.protocolNumber}</div>
            <div className="meeting-item">
              <h2>{meetingData.companyName}</h2>
              <span>
                <h4>{t("meetingsList.statusMeeting")}:</h4>
                <h4>
                  <strong>
                    {meetingData?.status === "ACTIVE"
                      ? t("meetingCreated.meetingStatus.active")
                      : meetingData.status === "PENDING"
                      ? t("meetingCreated.meetingStatus.pending")
                      : t("meetingCreated.meetingStatus.finish")}
                  </strong>
                </h4>
              </span>
              <span>
                <h5>{t("clientPage.infoStartMeeting")}:</h5>
                <h5>{getDate(meetingData?.startRegistration)}</h5>
              </span>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} className="button-grid">
          <GridInner>
            {loading ? (
              <Box sx={{ width: "60%" }}>
                <LinearProgress />
              </Box>
            ) : (
              userRole?.data?.map((role, idx) => (
                <>
                  {
                    <Button
                      key={idx.toString()}
                      variant="contained"
                      size="large"
                      onClick={() => navigateToMeetingDetail(role)}
                      sx={{
                        ":hover": {
                          border: "1px solid #208667",
                          color: "#fff",
                          background: "#208667",
                        },
                      }}
                    >
                      {`${t("mainTexts.modul4description")} (
                  ${
                    role?.memberTypeEnum === ROLES.SIMPLE
                      ? t("meetingCreated.roles.participants")
                      : role?.memberTypeEnum === ROLES.SECRETARY
                      ? t("meetingCreated.roles.secretary")
                      : role?.memberTypeEnum === ROLES.SPEAKER
                      ? t("meetingCreated.roles.speaker")
                      : role?.memberTypeEnum === ROLES.CHAIRMAN
                      ? t("meetingCreated.roles.chairman")
                      : t("meetingCreated.roles.addMember")
                  }
               )`}
                    </Button>
                  }
                </>
              ))
            )}
          </GridInner>
        </Grid>
      </Grid>
    </StyleMeetingCard>
  );
}

export default MeetingCard;
