import styled from "styled-components";
import { MIN_LENGTH } from "../../../../constants";

export const Wrapper = styled.div`
  background: var(--bgImage);
  min-height: 350px;

  .container {
    max-width: ${MIN_LENGTH}px;
    margin: auto;
    margin-top: 50px;
    padding-bottom: 50px;
  }

  .create-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    svg {
      fill: #208667;
    }
  }

  h5 {
    color: #208667;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  a.special {
    color: rgba(32, 134, 103, 0.5);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }
`;

export const CompaniesTitle = styled.h2`
  margin-bottom: 30px;
  color: #208667;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
