import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const MeetingParticipants = ({ meetingMember }) => {
  const { t } = useTranslation();

  const userTypeEnum = {
    SPEAKER: t("meetingCreated.roles.speaker"),
    WATCHER: t("meetingCreated.roles.watcher"),
    SECRETARY: t("meetingCreated.roles.secretary"),
    CHAIRMAN: t("meetingCreated.roles.chairman"),
    SIMPLE: t("memberMeeting"),
  };

  return (
    <MeetingParticipantsBox>
      <h3 className="section-title">{t("meetingParticipants")}</h3>
      <ul className="section-list">
        {meetingMember?.map((el, idx) => (
          <li className="section-item">
            <span className="section-item-text">{idx + 1}</span>
            <span className="section-item-text">{el.user.fullName}</span>
            <span className="section-item-text">
              {userTypeEnum[el.memberTypeEnum]}
            </span>
          </li>
        ))}
      </ul>
    </MeetingParticipantsBox>
  );
};

export default MeetingParticipants;

const MeetingParticipantsBox = styled.div`
  padding: 20px 0 20px 0 !important;
  border-bottom: 2px solid #ddf8f0;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  .section-title {
    margin-bottom: 20px !important;
    font-size: 22px;
  }

  .section-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 318px));
    column-gap: 30px;
    row-gap: 5px;
  }

  .section-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .section-item-text {
    text-align: center;
    font-size: 14px;
    padding: 8px !important;
  }
  .section-item-text:nth-child(1) {
    width: 73px;
  }

  .section-item-text:nth-child(2) {
    width: 123px;
  }

  .section-item-text:nth-child(3) {
    width: 118px;
  }
`;
