import React, { useState } from "react";
import { ArchiveMeetingDetailLeftBox } from "./style";
import ArchiveMeetingItem from "../ArchiveMeetingItem/ArchiveMeetingItem";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { API } from "../../../../../../services/api";
import { useEffect } from "react";

const ArchiveMeetingDetailLeft = ({
  archiveMeetings,
  setMeetingId,
  meetingId,
}) => {
  const [active, setActive] = useState(meetingId);
  const user = useSelector((state) => state.userData);

  const {
    data: userRole,
    loading,
    error,
  } = useQuery(
    `QUERIES_NAME.GET_MEMBER_ROLE_BY_MEETING ${active}`,
    async () => {
      const response = await API.getMemberRoleByMeeting({
        userId: user.id,
        meetingId: active,
      })
        .then((res) => res.data)
        .catch((err) => console.log(err));

      return response;
    }
  );

  useEffect(() => {
    if (!!userRole) {
      localStorage.setItem(
        "memberRole",
        JSON.stringify({
          meetingId: active,
          ...userRole[0],
        })
      );
    }
  }, [userRole]);

  const handleClick = (id) => {
    setActive(id);
    setMeetingId((prev) => id);
  };

  return (
    <ArchiveMeetingDetailLeftBox>
      <ul className="meeting-list">
        {archiveMeetings.map((el) => (
          <ArchiveMeetingItem
            el={el}
            handleClick={handleClick}
            active={active}
          />
        ))}
      </ul>
    </ArchiveMeetingDetailLeftBox>
  );
};

export default ArchiveMeetingDetailLeft;
