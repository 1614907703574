import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import React, { useMemo } from "react";
import { AddButton, MyButton, MySelect } from "../../../../../../components";
import styled from "styled-components";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { API } from "../../../../../../services/api";
import { CHAIRMAN } from "../../../../../../constants";
import { UserTable } from "../../../../../Admin/Company/style";
import { Box } from "@mui/system";
import MeetingModalUserList from "./MeetingModalUserList";
import { toast } from "react-toastify";
import { t } from "i18next";

const AddMeetingUserModal = ({ meetingId, members }) => {
  const [open, setOpen] = React.useState(false);
  const [selectUser, setSelectUser] = useState("");
  const { mutate: updateMember, isLoading: isAddLoading } = useMutation(
    async ({ data, refetchArr }) => {
      return await API.updateMember(data)
        .then((res) => {
          console.log(res.data);
          toast.success("successful!");
          refetchArr[7]?.refetch();
          refetchArr[6]?.refetch();
          setSelectUser("");
        })
        .catch((err) => {
          toast.error(t("errorOccurred"));
          console.log(err);
        });
    }
  );

  const membersUnReestredLoading = members[7]?.isLoading;
  const membersReestredLoading = members[6]?.isLoading;

  const membersUnReestred = !!members[7]?.data?.data
    ? members[7]?.data?.data?.filter((member) => !member.remotely)
    : [];
  const membersReestred = !!members[6]?.data?.data
    ? members[6]?.data?.data?.filter((member) => !member.remotely)
    : [];

  const selectData = useMemo(() => {
    const dataReestred = members[6]?.data?.data
      ? members[6]?.data?.data?.map((member) => ({
          ...member,
          label: member?.user.fullName,
        }))
      : [];

    const dataUnReestred = members[7]?.data?.data
      ? members[7]?.data?.data
          ?.filter((member) => member.memberTypeEnum === CHAIRMAN)
          .map((member) => ({
            ...member,
            label: member?.user.fullName,
          }))
      : [];

    return [...dataReestred, ...dataUnReestred];
  }, [members[6]?.data?.data, members[7]?.data?.data]);

  const changeUserId = (_, data) => {
    setSelectUser(data);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (selectUser?.id) {
      updateMember({
        data: { ...selectUser, remotely: false, confirmed: true },
        refetchArr: members,
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MyButton
        color="#fff"
        background="#208667"
        onClick={handleClickOpen}
        margin="20px 0"
      >
        {t("meetingCreated.roles.addMember")}
      </MyButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <AddUserForumBox>
            <p className="text">
              {t("userPage.meetingManagement.personalAppearances")}{" "}
            </p>
            <form className="form" onSubmit={onSubmit}>
              <div className="form-select">
                <MySelect
                  placeholder={t("userPage.meetingManagement.choose")}
                  value={selectUser}
                  data={selectData}
                  onChange={changeUserId}
                />
              </div>

              <AddButton type="submit" loading={isAddLoading} />
            </form>
          </AddUserForumBox>
        </DialogTitle>
        <DialogContent>
          {membersUnReestredLoading || membersReestredLoading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ width: "500px", height: "200px" }}
            >
              <CircularProgress
                color="success"
                style={{ width: "50px", height: "50px" }}
              />
            </Box>
          ) : (
            <DialogContentText id="alert-dialog-description">
              <UserTable aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>T/R</TableCell>
                    <TableCell sx={{ pR: "30px" }} align="center">
                      {t("FISH")}
                    </TableCell>
                    <TableCell align="center">{t("AdminUser.role")}</TableCell>
                    <TableCell align="center">
                      {t("userPage.meetingManagement.delete")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                {!!membersUnReestred && (
                  <MeetingModalUserList
                    simpleMembers={[...membersUnReestred, ...membersReestred]}
                    refetchMembers={members}
                    updateMember={updateMember}
                    deleteLoading={isAddLoading}
                  />
                )}
              </UserTable>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <MyButton
            color="#fff"
            background="#208667"
            onClick={handleClose}
            margin="20px 0"
          >
            {t("cancel")}
          </MyButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddMeetingUserModal;

const AddUserForumBox = styled.div`
  .text {
    margin-bottom: 5px;
    color: var(--text-drak, #000);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .form {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(100px, 1fr) 48px;
    gap: 20px;
  }

  .MuiAutocomplete-hasPopupIcon {
    border-radius: 6px;
    border: 1px solid #208667;
    background: var(--interactive-enabled, #fff);
    width: 100%;
    height: 48px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
  }

  .MuiOutlinedInput-root {
    padding: 5px !important;
  }
`;
