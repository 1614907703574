import { toast } from "react-toastify";
import { API } from "../../../../../../../services/api";

export const getCompanyFunc = async () => {
  const companyId = JSON.parse(localStorage.getItem("newMeetnigId"))?.companyId;
  if (!companyId) {
    toast.error("Meeting id topilmadi");
    return;
  }
  const res = await API.getCompanyByID(companyId)
    .then((res) => res.data)
    .catch((err) => console.log("Company:", err));

  return res;
};

export const createMeetingAgendaFunc = async ({
  t,
  data,
  issueFile,
  mutate,
  refetch,
  setData,
  meetingId,
}) => {
  return await API.createMeetingAgenda(data)
    .then((res) => {
      const fileData = new FormData();
      for (let file of issueFile?.files) {
        fileData.append("files", file);
      }
      fileData.append("agendaId", res.data.id);
      mutate({ fileData, agendaId: res.data.id });
      refetch();
      setData({
        subject: "",
        speakerId: "",
        debateEnum: "",
        speakTimeEnum: "",
        issueInfo: "",
      });
      toast.success(t("agendaCreated"));
    })
    .catch((err) => {
      if (
        err.response.data.title === "Subject is already have in this Meeting!"
      ) {
        toast.error(t("alreadyExistAgenda"));
      } else if (err.response.data.title === "Bad Request") {
        toast.error(t("enterData"));
      } else if (err.response.data.detail.includes("Speaker not found by ID")) {
        toast.error(t("speakerNotFound"));
      }
      console.log(err);
    });
};

export const createMeetingAgendaFileFunc = async ({
  fileData,
  agendaId,
  agendaDeleteMutate,
  setIssueFile,
  t,
}) => {
  return await API.createAgendaFile(fileData)
    .then((res) => {
      toast.success(t("fileUploaded"));
    })
    .catch((err) => {
      console.log(t("fileNotUploaded"), err);
      toast.error(t("fileTryAgain"));
      toast.warning(t("recreateAgenda"));
      agendaDeleteMutate(agendaId);
    })
    .finally(() => setIssueFile(""));
};

export const getAgendaDataFunc = async () => {
  const meetingId = JSON.parse(localStorage.getItem("newMeetnigId"));
  const res = await API.getAgendaByMeeting(meetingId)
    .then((res) => res.data)
    .catch((err) => console.log("agenda data:", err));

  return res;
};
