import React from "react";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import img from "../../../../../assets/images/kotib/1.png";
// import MeetingResult from "./Result";
import {
  ACTIVE,
  CANCELED,
  FINISH,
  PENDING,
  STATUSES,
  USERS_ROLES,
} from "../../../../../constants";
import { InnerRightDiv, MainDiv } from "../style";
import MyButton from "../../../../../components/UI/MyButton/MyButton";
import { API } from "../../../../../services/api";
import { RightButtons } from "../../ControlPanel/ControlPanelStyle";
import Votes from "../../Votes";
import { useLocation, useNavigate } from "react-router-dom";
import AddMeetingUserModal from "./AddMeetingUser/AddMeetingUserModal";
import { useTranslation } from "react-i18next";
import { withStyles } from "@mui/material";

function MeetingManage({
  meetingId,
  meetingStatus,
  refetch,
  activeAgenda,
  membersUnReestred,
  meetingInfo,
}) {
  const meetingData = useSelector((state) => state.socketMeeting);
  const { isLogged, user_role } = useSelector((store) => store.auth);
  const user = useSelector((state) => state.userData);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, mutate: changeMeetingStatus } = useMutation(
    async (data) => {
      const { id, status } = data;
      return await API.updateMeetingStatus({ id, status });
    },
    {
      onSuccess: ({ data }) => {
        if (
          data.status === STATUSES.FINISH ||
          data.status === STATUSES.CANCELED
        ) {
          navigate(
            `/${
              isLogged && user_role === USERS_ROLES.ADMIN ? "admin" : "user"
            }/comp/meetings#active`,
            {
              state: { comp: data.companyId },
            }
          );
        }
        refetch();
        const dataForComment = {
          userId: user.id,
          meetingId,
          loggingText:
            data.status === STATUSES.ACTIVE
              ? t("userPage.meetingManagement.startMeeting")
              : data.status === STATUSES.FINISH
              ? t("userPage.meetingManagement.finishMeeting")
              : data.status === STATUSES.CANCELED
              ? t("userPage.meetingManagement.meetingCanceled")
              : data.status === STATUSES.PENDING
              ? t("userPage.meetingManagement.meetingLate")
              : t("userPage.meetingManagement.changeStatus", {
                  status: data.status,
                }),
        };
        meetingData.socketClient.sendMessage(
          "/topic/user-all",
          JSON.stringify(dataForComment)
        );
      },
    }
  );

  const updateSoket = () => {
    const memberRole = localStorage.getItem("memberRole")
      ? JSON.parse(localStorage.getItem("memberRole"))
      : {};
    API.updateMemberData({ id: memberRole?.id })
      .then((res) => console.log("successful"))
      .catch((err) => console.log(err));
  };

  const updateMeetingStatus = (status) => {
    if (status === ACTIVE) {
      if (meetingData.countQuorum >= 66) {
        changeMeetingStatus({ id: meetingId, status });
      } else {
        toast.error("66% dan yuqori bo'lishi kerak");
      }
    } else {
      changeMeetingStatus({ id: meetingId, status });
    }
  };
  return (
    <MainDiv>
      <div>
        <img src={img} alt="img" />
      </div>
      <InnerRightDiv>
        <h3>
          {" "}
          {!!state && state?.status === "archive"
            ? t("userPage.meetingManagement.meetingInfo")
            : t("userPage.meetingManagement.meetingControl")}
        </h3>
        <RightButtons>
          <MyButton
            color="#fff"
            background="#208667"
            // variant="contained"
            onClick={() => updateSoket()}
          >
            {t("userPage.meetingManagement.reloadMeetingMember")}
          </MyButton>

          <p>
            {t("routes.controlPage.clientPage")}:
            <span>
              {meetingStatus.toUpperCase() == STATUSES.ACTIVE ? (
                t("meetingCreated.meetingStatus.active")
              ) : meetingStatus.toUpperCase() == STATUSES.PENDING ? (
                t("meetingCreated.meetingStatus.pending")
              ) : meetingStatus.toUpperCase() == STATUSES.FINISH ? (
                t("meetingCreated.meetingStatus.finish")
              ) : meetingStatus.toUpperCase() == STATUSES.CANCELED ? (
                t("meetingCreated.meetingStatus.canceled")
              ) : !!state && state?.status === "archive" ? (
                <span
                  className="text-danger"
                  style={{ color: "rgba(255, 0, 0, 0.9)" }}
                >
                  {t("userPage.meetingManagement.otherStatus")}
                </span>
              ) : (
                t("user.neaktiv")
              )}
            </span>
          </p>
          {/* TODO: StatisticDiv deleted */}
          <div style={{ marginTop: "10px" }}>
            {/* <b>из них:</b> */}
            <h5>
              {t("userPage.meetingManagement.remoteAppearances")}:
              {
                meetingData.members
                  ?.filter(
                    (element) =>
                      (element.confirmed && element.fromReestr) ||
                      (element.memberTypeEnum == "CHAIRMAN" &&
                        element.confirmed)
                  )
                  .filter((element1) => element1.remotely).length
              }
              ;
            </h5>
            <h5>
              {t("userPage.meetingManagement.personalAppearances")}:
              {
                meetingData.members
                  ?.filter(
                    (element) =>
                      (element.confirmed && element.fromReestr) ||
                      (element.memberTypeEnum == "CHAIRMAN" &&
                        element.confirmed)
                  )
                  .filter((element1) => !element1.remotely).length
              }
              ;
            </h5>
            <h5>
              {t("userPage.meetingManagement.quorum")}: (
              {meetingData?.countQuorum ? meetingData?.countQuorum : "0"}
              %)
            </h5>
            {meetingStatus.toUpperCase() == PENDING ||
            meetingStatus.toUpperCase() == ACTIVE ? (
              <AddMeetingUserModal
                meetingId={meetingId}
                members={membersUnReestred}
              />
            ) : null}
          </div>

          {meetingStatus.toUpperCase() == PENDING ? (
            <>
              <MyButton
                color="#fff"
                background="#208667"
                // variant="contained"
                onClick={() => updateMeetingStatus(ACTIVE)}
              >
                {t("userPage.meetingManagement.meetingActive")}
              </MyButton>
              <MyButton
                color="#fff"
                background="#208667"
                // variant="outlined"
                onClick={() => updateMeetingStatus(CANCELED)}
              >
                {t("userPage.meetingManagement.meetingCancele")}
              </MyButton>
            </>
          ) : meetingStatus.toUpperCase() == ACTIVE ? (
            <>
              <MyButton
                color="#fff"
                background="#208667"
                // variant="contained"
                onClick={() => updateMeetingStatus(FINISH)}
              >
                {t("userPage.meetingManagement.meetingfinish")}
              </MyButton>
              <MyButton
                color="#fff"
                background="#208667"
                // variant="contained"
                onClick={() => updateMeetingStatus(PENDING)}
              >
                {t("userPage.meetingManagement.meetingRepeat")}
              </MyButton>
              <MyButton
                color="#fff"
                background="#208667"
                // variant="outlined"
                onClick={() => updateMeetingStatus(CANCELED)}
              >
                {t("userPage.meetingManagement.meetingCancele")}
              </MyButton>
            </>
          ) : (
            !!state &&
            state?.status === "archive" && <Votes agenda={[activeAgenda]} />
          )}
        </RightButtons>
      </InnerRightDiv>
    </MainDiv>
  );
}

export default MeetingManage;
