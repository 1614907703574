import styled from "styled-components";

export const LanguagePage = styled.div`
  .navbarvictorina_language {
    position: relative;
  }

  .navbarvictorina_language-wrapper {
    padding: 8px 12px;
    height: 25px;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    border-radius: 8px;
    background: #ddf8f0;
  }
  .navbarvictorina_language-icon {
    width: 20px;
    height: 20px;
    color: #208667;
  }
  .navbarvictorina_language-iconArrow {
    width: 16px;
    height: 16px;
    color: #208667;
  }
  span {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #208667;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .navbarvictorina_language-bar {
    background: #ddf8f0;
    border-radius: 8px;
    display: none;
    position: absolute;
    flex-direction: column;
    align-items: center;
    width: 90px;
    right: 0;
    top: 41px;
    z-index: 10;
    overflow: hidden;
  }

  p {
    width: 100%;
    text-align: center;
    color: #208667;
    cursor: pointer;
    margin-top: 0;
    transition: 0.2s;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
