import React from "react";
import styled from "styled-components";
import { DeleteIcon } from "../../../../../../../../assets/icons";
import { ButtonSpinner } from "../../../../../../../../components";

const DeleteButton = (props) => {
  return (
    <DeleteButtonBox className="delete-button" {...props}>
      {props.loading ? (
        <ButtonSpinner />
      ) : (
        <img
          className="delete-icon"
          src={DeleteIcon}
          alt="delete"
          width={24}
          height={24}
        />
      )}
    </DeleteButtonBox>
  );
};

export default DeleteButton;

const DeleteButtonBox = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  z-index: 3;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 9px 7px 8px;
  background: #fff;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.08);
  border: 0;
  outline: 0;
  cursor: pointer;
`;
