import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { QUERIES_NAME } from "../../../../constants/apiqueries";
import { API } from "../../../../services/api";
import { BALLOT_OPTIONS } from "../../../../constants";
import ProgressBar from "../../../../components/ProgressbBar";
import { RightButtons } from "./style";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

function Vote(subject, voit, index, agenda, t) {
  const [voitFor, setVoitFor] = useState(0);
  const [voitRef, setVoitRef] = useState(0);
  const [voitIgn, setVoitIgn] = useState(0);

  const {
    data: voitData,
    isSuccess,
    isError,
    error,
  } = useQuery(`${QUERIES_NAME.GET_VOIT + voit?.id + index + 1}`, async () => {
    return await API.getVoitById({ id: voit.id });
  });

  const openVoits = () => {
    window.open(
      `${window.location.origin}/user/${voit?.id}/voits/${agenda?.id}`
    );
  };
  useEffect(() => {
    setVoitFor(0);
    setVoitRef(0);
    setVoitIgn(0);
    if (isSuccess) {
      voitData.data?.forEach((item) => {
        if (item.options === BALLOT_OPTIONS.FOR) {
          setVoitFor((prevState) => prevState + 1);
        } else if (item.options === BALLOT_OPTIONS.REFRAIN) {
          setVoitRef((prevState) => prevState + 1);
        } else {
          setVoitIgn((prevState) => prevState + 1);
        }
      });
    }
  }, [isSuccess, voitData?.data]);

  if (isSuccess) {
    const sum = voitFor + voitIgn + voitRef;
    const voitForPercentage = isNaN((voitFor / sum) * 100)
      ? 0
      : (voitFor / sum) * 100;

    const voitIgnPercentage = isNaN((voitIgn / sum) * 100)
      ? 0
      : (voitIgn / sum) * 100;

    const voitRefPercentage = isNaN((voitRef / sum) * 100)
      ? 0
      : (voitRef / sum) * 100;

    return (
      <InnerRightDiv>
        <h3>{t("userPage.meetingManagement.votesResult")}</h3>
        <RightButtons>
          <ProgressBar
            percent={"" + voitForPercentage + "%"}
            color="linear-gradient(270deg, rgba(221, 248, 240, 0.30) 0%, rgba(6, 94, 169, 0.30) 100%), linear-gradient(270deg, #3EB250 0%, rgba(62, 178, 80, 0.60) 100%), rgba(2, 119, 21, 0.00);
"
          >
            <span>
              {voitFor} {t("participant")}
            </span>
            <span>{"" + voitForPercentage + "%"}</span>
          </ProgressBar>
          <ProgressBar
            percent={"" + voitRefPercentage + "%"}
            color="linear-gradient(90deg, rgba(255, 0, 0, 0.24) 0%, rgba(221, 248, 240, 0.30) 100%), linear-gradient(270deg, #FFC700 0%, rgba(255, 199, 0, 0.40) 100%)"
          >
            <span>
              {voitRef} {t("participant")}
            </span>
            <span>{"" + voitRefPercentage + "%"}</span>
          </ProgressBar>
          <ProgressBar
            percent={"" + voitIgnPercentage + "%"}
            color="linear-gradient(90deg, rgba(255, 199, 0, 0.21) 0%, rgba(221, 248, 240, 0.30) 100%), linear-gradient(270deg, rgba(255, 0, 0, 0.90) 0%, rgba(255, 0, 0, 0.60) 100%)"
          >
            <span>
              {voitIgn} {t("participant")}
            </span>
            <span>{"" + voitIgnPercentage + "%"}</span>
          </ProgressBar>
        </RightButtons>
      </InnerRightDiv>
    );
  }
  return null;
}

function Votes({ agenda }) {
  const { t } = useTranslation();
  console.log(agenda);
  return (
    <>
      {agenda.length ? (
        agenda.map((item, index) =>
          item?.votingOptions?.map((voit, index) => {
            // console.log(item);
            // console.log(voit);
            return Vote(item.subject, voit, index, agenda, t);
          })
        )
      ) : (
        <h4>Hech narsa yo'q</h4>
      )}
    </>
  );
}

export default Votes;

const InnerRightDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  h3 {
    margin-bottom: 30px;
  }
`;
