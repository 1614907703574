import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useContext, useState, useEffect } from "react";
import { DetailRight, InnerRightDiv } from "./style";
import { useLocation, useParams } from "react-router-dom";
import { meetingTab, tabMenuTypes } from "./extra";
import { AgendaContext } from "../../pages/MeetingDetails/context/MeetingDetailContext";
import { useSelector } from "react-redux";
import { CHAIRMAN, SIMPLE, STATUSES } from "../../../../constants";
import Chat from "../Chat";
import Files from "../Files";
import { AgendaVoiting } from "./components";
import Protocols from "../Protocol";
import { useQueries } from "react-query";
import { API } from "../../../../services/api";
import { QUERIES_NAME } from "../../../../constants/apiqueries";
import { dispatch } from "../../../../redux/index";
import Loading from "../../../../components/Loading";
import MeetingManage from "./components/MeetingManage";
import Votes from "./components/Votes";
import { useTranslation } from "react-i18next";

function MeetingDetailRight(props) {
  const { children, value, index, ...other } = props;

  return (
    <InnerRightDiv
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="p">{children}</Typography>
        </Box>
      )}
    </InnerRightDiv>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ activeVideo }) {
  const [loading, setLoading] = useState(true);
  const { agendas, activeAgenda, isVotes } = useContext(AgendaContext);
  const [realMS, setRealMS] = useState("");
  const user = useSelector((store) => store.userData);
  const { state } = useLocation();
  const socketMeetingData = useSelector((state) => state.socketMeeting);
  const { t } = useTranslation();
  const memberRole = localStorage.getItem("memberRole")
    ? JSON.parse(localStorage.getItem("memberRole"))
    : {};
  const { meetingId } = useParams();

  // const meetingId = memberRole.meetingId;
  const tabArr = meetingTab(t);
  const isStateValue = tabArr[state.status][memberRole.memberTypeEnum];

  const [value, setValue] = useState(isStateValue[0]?.type);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const queries = [
    {
      key: QUERIES_NAME.GET_MEMBER_ROLE_BY_MEETING,
      fnc: async () =>
        API.getMemberRoleByMeeting({ userId: user.id, meetingId }),
    },
    {
      key: QUERIES_NAME.GET_MEETING,
      fnc: async () => API.getMeeting({ meetingId }),
    },
    {
      key: QUERIES_NAME.GET_AGENDES,
      fnc: async () => API.getAgendaByMeeting({ id: meetingId }),
    },
    {
      key: QUERIES_NAME.GET_FILES,
      fnc: async () => API.getFilesByMeeting({ id: meetingId }),
    },
    {
      key: QUERIES_NAME.GET_LOGGING_BY_MEETING,
      fnc: async () => API.getLoggingByMeeting({ id: meetingId }),
    },
    {
      key: QUERIES_NAME.GET_QUESTIONS_BY_MEETING,
      fnc: async () => API.getQuestionByMeeting({ id: meetingId }),
    },
    {
      key: "Meeting member reestr",
      fnc: async () =>
        API.getMeetingMember({ meetingId: meetingId, fromReestr: true }),
    },
    {
      key: "Meeting member",
      fnc: async () =>
        API.getMeetingMember({ meetingId: meetingId, fromReestr: false }),
    },
  ];
  const meetingData = useQueries(
    queries.map((query) => {
      const { key, fnc } = query;
      return {
        queryKey: [`${key}`],
        queryFn: fnc,
      };
    })
  );
  const loggings = meetingData[4]?.data?.data;
  const membersUnReestred = meetingData[7]?.data?.data;
  const membersReestred = meetingData[6]?.data?.data;
  const questions = meetingData[5]?.data?.data;
  const success = meetingData.every((data) => data.status === "success");
  const meetingInfo = meetingData[1]?.data?.data;
  const refetchAll = () => {
    meetingData.forEach((result) => result.refetch());
  };
  useEffect(() => {
    if (meetingInfo?.status) {
      setRealMS(meetingInfo?.status);
    }
  }, [meetingInfo, agendas]);

  useEffect(() => {
    let suc = meetingData.every((data) => data.status === "success");
    console.log(membersUnReestred, membersReestred, questions, loggings);
    if (suc) {
      dispatch.socketMeeting.updateMeetingData({
        answers: questions,
        informations: loggings,
        members: [...membersUnReestred, ...membersReestred],
        countQuorum: parseInt(
          ([...membersUnReestred, ...membersReestred]?.filter(
            (element) =>
              element.confirmed &&
              (element.fromReestr || element.memberTypeEnum === CHAIRMAN)
          ).length /
            [...membersUnReestred, ...membersReestred]?.filter(
              (element) =>
                element.fromReestr || element.memberTypeEnum === CHAIRMAN
            ).length) *
            100
        ),
      });
      setLoading(false);
    }
  }, [success, questions, loggings, membersUnReestred, membersReestred]);

  useEffect(() => {
    API.updateMemberData({ id: memberRole?.id })
      .then((res) => console.log("successful"))
      .catch((err) => console.log(err));
  }, []);

  return (
    <DetailRight style={{ display: !activeVideo ? "block" : "none" }}>
      {!loading ? (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              aria-label="basic tabs example"
              onChange={handleChange}
            >
              {tabArr[state.status][memberRole.memberTypeEnum].map((el) => (
                <Tab label={el.label} value={el.type} {...a11yProps(el.type)} />
              ))}
            </Tabs>
          </Box>
          {agendas?.length > 0 || activeAgenda ? (
            <>
              <MeetingDetailRight
                value={value}
                index={tabMenuTypes.CONTROL_PANEL}
              >
                <MeetingManage
                  meetingId={meetingId}
                  meetingStatus={realMS}
                  refetch={refetchAll}
                  activeAgenda={activeAgenda}
                  meetingInfo={meetingInfo}
                  membersUnReestred={meetingData}
                />
              </MeetingDetailRight>

              <MeetingDetailRight value={value} index={tabMenuTypes.INFO}>
                <Chat value={tabMenuTypes.INFO} />
              </MeetingDetailRight>

              <MeetingDetailRight value={value} index={tabMenuTypes.FILES}>
                <Files />
              </MeetingDetailRight>

              <MeetingDetailRight value={value} index={tabMenuTypes.VOTES}>
                <Votes agenda={[activeAgenda]} />
              </MeetingDetailRight>

              <MeetingDetailRight
                value={value}
                index={tabMenuTypes.CREATE_VOTES}
              >
                {isVotes ||
                socketMeetingData.countQuorum < 66 ||
                meetingInfo?.status?.toUpperCase() != STATUSES.ACTIVE ? (
                  <Votes agenda={[activeAgenda]} />
                ) : (
                  <AgendaVoiting
                    setValue={setValue}
                    memberRole={memberRole}
                    socketMeetingData={socketMeetingData}
                    meetingInfo={meetingInfo}
                  />
                )}
              </MeetingDetailRight>

              <MeetingDetailRight value={value} index={tabMenuTypes.PROTOCOL}>
                <Protocols data={membersReestred} />
              </MeetingDetailRight>
            </>
          ) : (
            <p style={{ textAlign: "center" }}>
              {t("userPage.notMeetingDetails")}
            </p>
          )}
        </Box>
      ) : (
        <Loading />
      )}
    </DetailRight>
  );
}
