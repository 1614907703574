import styled from "styled-components";
import { MIN_LENGTH } from "../../constants";

export const Wrapper = styled.div`
  max-width: ${MIN_LENGTH}px;
  margin: auto;

  .nav-list {
    display: flex;
    align-items: center;
    gap: 25px;
    padding-top: 45px;
    margin-left: -30px;
  }

  .nav-link {
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: 0.5s;
    padding: 8px 12px;
  }

  .nav-link:hover {
    border-radius: 8px;
    background: #fff;
    color: #208667;
  }
`;
