import React from "react";
import { PlusIcon } from "../../assets/icons";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

const AddButton = (props) => {
  return (
    <AddButtonBox className="form-btn" {...props}>
      {props.loading ? (
        <CircularProgress
          color="success"
          style={{ width: "24px", height: "24px" }}
        />
      ) : (
        <img
          className="form-btn-img"
          src={PlusIcon}
          alt="add-button"
          width={24}
          height={24}
        />
      )}
    </AddButtonBox>
  );
};

export default AddButton;

const AddButtonBox = styled.button`
  display: block !important;
  width: 100%;
  max-width: ${(props) => (props.width ? props.width : "100%")};
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #208667;
  background: #fff;
  cursor: pointer;
`;
