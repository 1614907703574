import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ArrowRight } from "../../assets/icons";

const Breadcrumb = ({ list }) => {
  return (
    <BreadcrumbBox className="breadcrumb-box">
      <Box className="breadcrumb" component={"ul"}>
        {list.map((el) =>
          !el?.isActive ? (
            <Box component={"li"} className="breadcrumb-item">
              <Link className="breadcrumb-link" to={el.link}>
                {el.label}
              </Link>
              <Box
                component={"img"}
                src={ArrowRight}
                alt="arrow right"
                width={10}
                height={10}
              />
            </Box>
          ) : (
            <Box component={"li"} className="breadcrumb-item">
              {el.label}
            </Box>
          )
        )}
      </Box>
    </BreadcrumbBox>
  );
};

export default Breadcrumb;

const BreadcrumbBox = styled.div`
  padding-top: 40px !important;
  padding-bottom: 40px !important;

  .breadcrumb {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
  }

  .breadcrumb-item {
    display: flex;
    gap: 5px;
    align-items: center;
    color: #208667;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  .breadcrumb-link {
    color: rgba(32, 134, 103, 0.5);
  }
`;
