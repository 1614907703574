import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import styled from "styled-components";

const MySelect = ({
  data,
  onChange,
  value,
  maxwidth,
  placeholder,
  required,
  loading,
}) => {
  return (
    <SelectBox maxwidth={maxwidth}>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={data}
        sx={{ width: maxwidth }}
        value={value}
        onChange={onChange}
        loading={loading}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} required={true} />
        )}
      />
    </SelectBox>
  );
};

export default MySelect;

const SelectBox = styled.div`
  * {
    box-sizing: border-box;
  }
  .MuiAutocomplete-hasPopupIcon {
    border-radius: 6px;
    border: 1px solid #208667;
    background: var(--interactive-enabled, #fff);
    width: 100%;
    max-width: ${(props) =>
      props.maxwidth ? props.maxwidth + "px" : "100%"} !important;
    height: 48px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0 !important;
  }

  .MuiOutlinedInput-root {
    /* padding: 5px !important; */
  }

  .MuiTextField-root,
  .MuiOutlinedInput-root {
    height: 48px;
  }

  &::placeholder {
    color: #9d9d9d;
  }
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
