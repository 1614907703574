import routeNames from "../constants/routeNames";
import CompanyData from "../pages/Admin/Company";
import CreateCompany from "../pages/Admin/Company/components/CreateCompany";
import Information from "../pages/Admin/Main";
import MeetingData from "../pages/Admin/Meeting";
import MeetingCreate from "../pages/Admin/Meeting/components/MeetingCreate";
import UserData from "../pages/Admin/User";
import CreateUser from "../pages/Admin/User/components/CreatUser";
import EditUser from "../pages/Admin/User/components/EditUser";
import Home from "../pages/Home";
import MyProfile from "../pages/MyProfile";
import EditUserData from "../pages/MyProfile/components/EditUser";
import ArchiveMeeting from "../pages/Users/components/ArchiveMeeting";
import Companies from "../pages/Users/pages/Companies";
import Meetings from "../pages/Users/pages/Meetings";
import MeetingDetails from "../pages/Users/pages/MeetingDetails";
import MeetingCreated from "../pages/Admin/Meeting/components/MeetingCreated/MeetingCreated";
import CompanyEdit from "../pages/Admin/Company/components/EditCompany";
import EditMeeting from "../pages/Admin/Meeting/components/EditMeeting/EditMeeting";
import MeetingQrCode from "../pages/MeetingQrCode/MeetingQrCode";

export const PublicRoutes = [
  {
    path: routeNames.LOGIN,
    element: <Home />,
  },
  {
    path: routeNames.MEETING_PROTOCOL_QR_CODE_PAGE,
    element: <MeetingQrCode />,
  },
];

export const PrivateRoutes = [
  {
    path: routeNames.ADMIN,
    element: <Information />,
  },
  {
    path: routeNames.PROFILE,
    element: <MyProfile />,
  },
  {
    path: routeNames.ADMIN_USER,
    element: <UserData />,
  },
  {
    path: routeNames.USER_MEETINGS,
    element: <Meetings />,
  },
  {
    path: routeNames.USER_MEETINGS_ONE,
    element: <Meetings />,
  },
  {
    path: routeNames.ARCHIVE_MEETING,
    element: <Meetings />,
  },
  {
    path: routeNames.ADMIN_MEETINGS_EDIT,
    element: <EditMeeting />,
  },
  {
    path: routeNames.ADMIN_USER_CREATE,
    element: <CreateUser />,
  },
  {
    path: routeNames.ADMIN_USER_EDIT,
    element: <EditUser />,
  },
  {
    path: routeNames.ADMIN_COMPANY,
    element: <CompanyData />,
  },
  {
    path: routeNames.ADMIN_COMPANY_CREATE,
    element: <CreateCompany />,
  },
  {
    path: routeNames.ADMIN_SECRETARY,
    element: <Companies />,
  },
  {
    path: routeNames.EDIT_PROFILE,
    element: <EditUserData />,
  },
  {
    path: routeNames.USER_COMPANIES,
    element: <Companies />,
  },
  {
    path: routeNames.ADMIN_MEETINGS,
    element: <MeetingData />,
  },
  {
    path: routeNames.ADMIN_MEETING_CREATE,
    element: <MeetingCreate />,
  },
  {
    path: routeNames.ADMIN_MEETING_CREATED_MEETING,
    element: <MeetingCreated />,
  },
  {
    path: routeNames.MEETING_DETAILS_USER,
    element: <MeetingDetails />,
  },
  {
    path: routeNames.MEETING_DETAILS,
    element: <MeetingDetails />,
  },
  {
    path: routeNames.ADMIN_COMPANY_EDIT,
    element: <CompanyEdit />,
  },
];
