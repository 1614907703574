import { TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import { CHAIRMAN, SIMPLE } from "../../../../../../constants";
import styled from "styled-components";
import { useState } from "react";
import { DeleteIcon } from "../../../../../../assets/icons";
import { ButtonSpinner } from "../../../../../../components";
import { useTranslation } from "react-i18next";

const MeetingModalUserList = ({
  simpleMembers,
  refetchMembers,
  deleteLoading,
  updateMember,
}) => {
  const [deleteUser, setDeleteUser] = useState("");
  const { t } = useTranslation();

  const deleteMember = (member) => {
    setDeleteUser(member.id);
    updateMember({
      data: { ...member, remotely: true },
      refetchArr: refetchMembers,
    });
    refetchMembers[7]?.refetch();
    refetchMembers[6]?.refetch();
  };
  const userRole = (memberTypeEnum) => {
    if (memberTypeEnum === SIMPLE)
      return t("userPage.meetingManagement.memberBoard");
    else if (memberTypeEnum === CHAIRMAN)
      return t("meetingCreated.roles.chairman");
    else return t("meetingCreated.roles.speaker");
  };

  return (
    <MeetingUserAdd className="table-body">
      {simpleMembers?.map((member, index) => (
        <TableRow
          key={index}
          className="list-item"
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell component="th" scope="row">
            {index + 1}
          </TableCell>
          <TableCell align="right">{member?.user?.fullName}</TableCell>
          <TableCell align="right">
            {userRole(member?.memberTypeEnum)}
          </TableCell>
          <TableCell align="right">
            <DeleteButtonBox
              className="delete-button"
              onClick={() => deleteMember(member)}
            >
              {deleteLoading && deleteUser === member?.id ? (
                <ButtonSpinner />
              ) : (
                <img
                  className="delete-icon"
                  src={DeleteIcon}
                  alt="delete"
                  width={24}
                  height={24}
                />
              )}
            </DeleteButtonBox>
          </TableCell>
        </TableRow>
      ))}
    </MeetingUserAdd>
  );
};

export default MeetingModalUserList;

const MeetingUserAdd = styled(TableBody)`
  .table-body {
    max-height: 150px !important;
    overflow-y: auto !important;
    border: 1px solid red !important;
  }
`;

const DeleteButtonBox = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 9px 7px 8px;
  margin: 0 10px;
  background: #fff;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.08);
  border: 0;
  outline: 0;
  cursor: pointer;
`;
