import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  meetingGetByIDFunc,
  meetingGetCities,
  meetingGetCompaniesFunc,
  updateMeetingFunc,
} from "./queryColbackFunc";
import { Box, Button, CircularProgress, MenuItem, Select } from "@mui/material";
import { Wrapper } from "./EditMeetingStyle";
import routeNames from "../../../../../constants/routeNames";
import { useTranslation } from "react-i18next";
import { editInputText } from "../../../../../utils";

const changeValue = (setValue, data) => {
  setValue("companyId", data.companyId);
  setValue("companyName", data.companyName);
  setValue("cityId", data.cityId);
  setValue("protocolNumber", data.protocolNumber);
  setValue("address", data.address);
  setValue("description", data.description);
  setValue("status", data.status);
  setValue("typeEnum", data.typeEnum);
  // setValue("extraInfo", data.extraInfo ? data.extraInfo : "");
  setValue("startDate", data.startDate.slice(0, data.startDate.length - 1));
  setValue(
    "startRegistration",
    data.startRegistration.slice(0, data.startRegistration.length - 1)
  );
  setValue(
    "endRegistration",
    data.endRegistration.slice(0, data.endRegistration.length - 1)
  );
};

const EditMeeting = () => {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const { meetingId } = useParams();
  const { state } = useLocation();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const { data: companies, error } = useQuery(
    "getAllCompanies",
    meetingGetCompaniesFunc
  );

  const { data: cities } = useQuery("cities", meetingGetCities);
  const { data: meeting, isLoading: meetingLoading } = useQuery(
    "meetingData",
    async () => await meetingGetByIDFunc(meetingId),
    {
      onSuccess: (data) => {
        if (!!data) {
          changeValue(setValue, data);
        }
      },
    }
  );

  const { mutate: updateMeeting, isLoading: updateMeetingLoading } =
    useMutation((props) => updateMeetingFunc({ data: props, navigation, t }));

  const onSubmit = (data) => {
    const city = cities?.find((city) => city?.id === data?.cityId);
    const company = companies?.find(
      (company) => company?.id === data?.companyId
    )?.name;

    const startD = data.startDate;
    const startR = data.startRegistration;
    const endR = data?.endRegistration;

    const modifiedData = {
      ...data,
      city: city,
      address: editInputText(data.address),
      description: editInputText(data.description),
      companyName: company,
      status: "PENDING",
      protocolNumber: data?.protocolNumber,
      startDate: startD.length === 16 ? startD + ":00.000Z" : startD + ".000Z",
      startRegistration:
        startR.length === 16 ? startR + ":00.000Z" : startR + ".000Z",
      endRegistration: endR.length === 16 ? endR + ":00.000Z" : endR + ".000Z",
      id: meetingId,
    };

    updateMeeting(modifiedData);
  };

  if (meetingLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={"80vh"}
      >
        <CircularProgress
          color="success"
          style={{ width: "100px", height: "100px" }}
        />
      </Box>
    );
  }
  return (
    <Wrapper>
      <div className="container">
        <Box className="create-top">
          <Link to="/admin">{t("home")}</Link>
          <Link to={routeNames.ADMIN_MEETINGS}>{t("meetings")}</Link>
          <h5>{t("routes.addOrEditPage.editMeeting")}</h5>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} className="create-form">
          <Box className="form-list" component={"ul"}>
            <Box className="form-item" component={"li"}>
              <label>
                {t("routes.controlPage.clientPage")}
                <Select
                  {...register("companyId", { required: true })}
                  className="create-select"
                  value={watch("companyId") ? watch("companyId") : ""}
                >
                  {companies?.map((status) => (
                    <MenuItem key={status?.id} value={status?.id}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              </label>
              <label>
                {t("cityOrRegion")}
                <Select
                  {...register("cityId", { required: true })}
                  className="create-select"
                  value={watch("cityId") ? watch("cityId") : ""}
                >
                  {cities?.map((cities) => (
                    <MenuItem key={cities?.id} value={cities?.id}>
                      {cities.nameUz}
                    </MenuItem>
                  ))}
                </Select>
              </label>

              <label>
                {t("meetingsList.commentMeeting")}
                <textarea
                  {...register("description")}
                  placeholder={t("companyForm.enter")}
                  style={{ height: "120px" }}
                />
              </label>

              {/* <label>
                {t("meetingCreated.meetingExtraInfo")}
                <input
                  {...register("extraInfo", { required: true })}
                  type="text"
                  required
                  placeholder={t("companyForm.enter")}
                />
              </label> */}
            </Box>

            <Box className="form-item" component={"li"}>
              <label>
                {t("idProtocol")} *
                <input
                  {...register("protocolNumber", { required: true })}
                  type="number"
                  required
                  placeholder={t("companyForm.enter")}
                />
              </label>
              <label>
                {t("meetingsList.address")}
                <input
                  {...register("address", { required: true })}
                  type="text"
                  required
                  placeholder={t("companyForm.enter")}
                />
              </label>
              <label>
                {t("meetingsList.startMeeting")}
                <input
                  {...register("startDate", { required: true })}
                  type="datetime-local"
                />
              </label>
              <label>
                {t("meetingsList.startRegister")}
                <input
                  {...register("startRegistration", { required: true })}
                  type="datetime-local"
                />
              </label>
              <label>
                {t("meetingsList.finishRegister")}
                <input
                  {...register("endRegistration", { required: true })}
                  type="datetime-local"
                />
              </label>
            </Box>
          </Box>

          <Box className="create-submit">
            <Button
              onClick={handleSubmit(onSubmit)}
              className="form-submit"
              variant="contained"
              color="success"
            >
              {updateMeetingLoading ? <CircularProgress /> : t("settings")}
            </Button>
            <Button
              color="success"
              className="delete-btn"
              variant="outlined"
              onClick={() => navigation(routeNames.ADMIN_MEETINGS)}
            >
              {t("cancel")}
            </Button>
          </Box>
        </form>
      </div>
    </Wrapper>
  );
};

export default EditMeeting;
