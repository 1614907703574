import { Box, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { MyButton } from "../../../../../components";
import { DeleteIcon, EditIcon } from "../../../../../assets/icons";
import { Link } from "react-router-dom";
import { green } from "@mui/material/colors";

const SearchMeetingList = ({ data, mutate, meetingDeleteLoading }) => {
  const [activeBtn, setActiveBtn] = useState(null);

  const deleteMeeting = (id) => {
    mutate(id);
  };

  return (
    <ul className="search-list">
      {data?.map((meeting) => (
        <li
          className="search-item"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "10px",
          }}
        >
          <span>{meeting?.companyName}</span>

          <Box
            className="menu-box"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {meetingDeleteLoading && meeting.id === activeBtn ? (
              <MyButton disabled={true} padding="5px 15px">
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                  }}
                />
              </MyButton>
            ) : (
              <MyButton
                padding="5px"
                className="meeting-search-delete"
                onClick={() => {
                  setActiveBtn(meeting?.id);
                  deleteMeeting(meeting?.id);
                }}
              >
                <img
                  src={DeleteIcon}
                  alt="DeleteIcon"
                  className="delete-icon"
                />
              </MyButton>
            )}
            <Link
              state={{ meetingId: meeting?.id }}
              to={`/admin/meetings/edit/${meeting?.id}`}
            >
              <img src={EditIcon} alt="EditIcon" />
            </Link>
          </Box>
        </li>
      ))}
    </ul>
  );
};

export default SearchMeetingList;
