import React from "react";
import styled from "styled-components";

const MyButton = ({ children, ...args }) => {
  return <MyButtonBox {...args}>{children}</MyButtonBox>;
};

export default MyButton;

const MyButtonBox = styled.button`
  * {
    box-sizing: border-box;
  }

  width: ${(props) => (props.width ? props.width : "auto")};
  margin: ${(props) => (props.margin ? props.margin : "0 0")};
  padding: ${(props) =>
    props.padding ? props.padding : "12px 22px"} !important;
  text-align: center;
  color: ${(props) => props.color};
  font-family: var(--fontFamily);
  font-size: ${(props) => (props.size ? props.size + "px" : "14px")};
  font-style: normal;
  font-weight: ${(props) => (props.weight ? props.weight : "600")};
  line-height: normal;
  border-radius: 8px;
  border: ${(props) =>
    props.border ? "1px solid" + props.border : "transparent"};
  background: ${(props) =>
    props.background ? props.background : "transparent"};
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    background: ${(props) =>
      props.backgroundHover ? props.backgroundHover : props.background};
    color: ${(props) => (props.hoverColor ? props.hoverColor : props.color)};
  }
`;
