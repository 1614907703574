import styled from "styled-components";
export const ArchiveMeetingDetailRightBox = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  padding-left: 30px;

  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  .meeting-right-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }

  .meeting-right-title {
    color: #208667;
    font-size: 22px;
    font-weight: 700;
  }

  .meeting-right-list {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-right: 30px;
    padding-left: 5px;
    height: 426px;
    overflow-y: scroll;
  }

  .loading-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ddf8f0;
    height: 100%;
  }
`;

export const ArchiveMeetingDetailRightCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 16px 20px;
  border-radius: 12px;
  border: 2px solid #ddf8f0;
  background: #fff;
  cursor: pointer;
  transition: all 0.3s linear;

  &.active {
    border-color: #208667;
  }
  &:hover {
    border: 2px solid #208667;
    transition: all 0.3s linear;
    transform: scale(1.01);
  }
`;

export const ArchiveMeetingCardInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ArchiveMeetingTitle = styled.h4`
  margin: 0;
`;

export const ArchiveMeetingTopic = styled.p`
  margin: 0;
  padding: 3px 10px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 8px;
  background: #ddf8f0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const VerticalLine = styled.div`
  width: 4px;
  height: 463px;
  border-radius: 10px;
  background: #ddf8f0;
`;

export const ProtocolLink = styled.a`
  padding: 8px 16px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
  background: #208667;
`;
