import React from "react";
import styled from "styled-components";

const MyTextarea = ({ children, ...args }) => {
  return (
    <MyTextareaBox required={args.required} {...args}>
      {children}
    </MyTextareaBox>
  );
};

export default MyTextarea;

const MyTextareaBox = styled.textarea`
  * {
    box-sizing: border-box;
  }
  display: block;
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth + "px" : "100%")};
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #208667;
  background: var(--interactive-enabled, #fff);
  outline: 0;

  &::placeholder {
    color: #9d9d9d;
  }
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
