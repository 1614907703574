import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SockJsClient from "react-stomp";
import { dispatch } from "../redux/index";
import { URL } from "../constants";

export const Socket = ({ meetingId, memberRole, currentMember }) => {
  const clientRef = useRef(null);
  const token = useSelector((state) => state.auth.token);
  const members = useSelector((state) => state.socketMeeting?.members);
  let url = `${URL}/websocket/logger/?access_token=${token}`;

  const topics = [
    `/topic/user/${meetingId}`,
    `/topic/answer/${meetingId}`,
    `/topic/getMember/${meetingId}`,
    `/topic/get-zoom/${meetingId}`,
    `/topic/meeting-status/${meetingId}`,
    `/topic/quorum/${meetingId}`,
  ];

  useEffect(() => {
    dispatch.socketMeeting.updateMeetingData({
      socketClient: clientRef.current,
    });
    // return () => {
    //   dispatch.socketMeeting.updateMeetingData({ socketClient: null });
    // };
  }, [dispatch]);

  return (
    <SockJsClient
      ref={clientRef}
      url={url}
      topics={topics}
      autoReconnect={true}
      onConnectFailure={(e)=>{
        console.log(e)
      }}
      onConnect={() => {
        const data = {
          memberId: memberRole?.id,
          online: true,
        };
        clientRef?.current?.sendMessage(
          "/topic/setStatus",
          JSON.stringify(data)
        );
        dispatch.socketMeeting.updateMeetingData({ isConnected: true });
      }}
      onDisconnect={() => {
        console.log("disconnected");
        dispatch.socketMeeting.updateMeetingData({
          isConnected: false,
          socketClient: null,
        });
      }}
      onMessage={(msg, topic) => {
        console.log(msg, "global msg");
        if (topic === "/topic/user/" + meetingId) {
          dispatch.socketMeeting.updateMeetingData({ informations: msg });
        }
        if (topic === "/topic/answer/" + meetingId) {
          console.log(msg, "answers");
          dispatch.socketMeeting.updateMeetingData({ answers: msg });
        }
        if (topic === "/topic/getMember/" + meetingId) {
          console.log(msg);
          dispatch.socketMeeting.updateMeetingData({
            members: msg,
            countQuorum: parseInt(
              (msg?.filter(
                (element) =>
                  (element.confirmed && element.fromReestr) ||
                  element.memberTypeEnum == "CHAIRMAN"
              ).length /
                msg?.filter(
                  (element) =>
                    element.fromReestr || element.memberTypeEnum == "CHAIRMAN"
                ).length) *
                100
            ),
          });
        }
        if (topic === "/topic/get-zoom/" + meetingId) {
          console.log(msg, "zoom");
        }
        if (topic === "/topic/meeting-status/" + meetingId) {
          console.log(msg, "status meeting");
        }
        if (topic === "/topic/quorum/" + meetingId) {
          console.log(msg, "quorum");
          dispatch.socketMeeting.updateMeetingData({
            countQuorum: parseInt(
              (msg?.filter(
                (element) =>
                  element.confirmed &&
                  (element.fromReestr ||
                    element.memberTypeEnum == "CHAIRMAN")
              ).length /
                members?.filter(
                  (element) =>
                    element.fromReestr ||
                    element.memberTypeEnum == "CHAIRMAN"
                ).length) *
                100
            ),
          });
        }
      }}
    />
  );
};

export default Socket;
