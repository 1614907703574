import { API } from "../../services/api";
import getUserRole from "../../utils/getUserRole";

const initialState = {
  socketClient: null,
  isConnected: false,
  tableUsers: [],
  answers: [],
  members: [],
  informations: [],
  zoom: {
    callMeeting: false,
    meetingPassword: "",
  },
  meetingStatus: false,
  countQuorum: 0,
};

const socketMeeting = {
  state: initialState, // initial state
  reducers: {
    // handle state changes with pure functions
    updateMeetingData(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    resetMeetingData() {
      return initialState;
    },
  },
};

export default socketMeeting;
