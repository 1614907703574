import { CircularProgress } from "@mui/material";
import React from "react";
import { StyledLoading } from "./style";

function Loading() {
  return (
    <StyledLoading>
      <CircularProgress size={60} />
    </StyledLoading>
  );
}

export default Loading;
