import React from "react";
import { Img, StyledBox, Wrapper } from "./style";
import { Box } from "@mui/material";
import { EProtocol, NavoiyUran } from "../../assets/icons";
import Language from "../Language";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <StyledBox>
      <Wrapper>
        <Box className="navbar-list">
          <Box>
            <Link to="/">
              <Img src={EProtocol} alt="EProtocol" />
            </Link>
            <Img src={NavoiyUran} alt="Navoiy Uran" />
          </Box>
          <Language />
        </Box>
      </Wrapper>
    </StyledBox>
  );
}

export default Navbar;
