import styled from "styled-components";
import { MIN_LENGTH } from "../../constants";
import { Box } from "@mui/material";

export const StyledBox = styled(Box)`
  background: #208667;
  padding: 30px 0;

  .footer-top {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .footer-list {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 15px;
  }

  h4 {
    color: var(--base-white, #fff);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.36px;
    margin-bottom: 0;
  }
`;

export const Wrapper = styled.div`
  max-width: ${MIN_LENGTH}px;
  margin: auto;

  .footer-link {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .footer-links {
    width: 25px;
    height: 25px;
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Img = styled.img`
  width: 200px;
`;

export const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const FlexPage = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0 50px;
`;
