import React, { useEffect } from "react";
import { Wrapper } from "./MeetingCreatedStyle";
import { Breadcrumb, MyButton } from "../../../../../components";
import { AgendaInfo, AssemblyData, MeetingParticipants } from "./components";
import { useQuery } from "react-query";
import { API } from "../../../../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

const MeetingCreated = () => {
  const { t } = useTranslation();

  const breadcrumbList = [
    {
      id: 1,
      label: t("home"),
      isActive: false,
      link: "/admin",
    },
    {
      id: 1,
      label: t("routes.controlPage.user"),
      isActive: false,
      link: "/admin/meetings",
    },
    {
      id: 1,
      label: t("UsersHeading.save"),
      isActive: true,
      link: "/admin/meetings/create",
    },
  ];

  const { meetingId } = useParams();
  const navigate = useNavigate();
  const {
    data: meeting,
    isLoading: meetingLoading,
    error: meetingError,
  } = useQuery("getMeetingData", async (payload) => {
    const res = await API.getMeetingByID(meetingId)
      .then((res) => {
        localStorage.setItem("newMeetnigId", JSON.stringify(res.data));
        return res.data;
      })
      .catch((err) => console.log("Meeting topilmadi:", err));
    return res;
  });

  const {
    data: meetingAgenda,
    isLoading: meetingAgendaLoading,
    error: meetingAgendaError,
  } = useQuery("getMeetingAgenda", async () => {
    const res = await API.getAgendaByMeeting({ id: meetingId })
      .then((res) => res.data)
      .catch((err) => console.log(err));
    return res;
  });

  const {
    data: simpleMeetingMember,
    isLoading: simpleMeetingMemberLoading,
    error: simpleMeetingMemberError,
  } = useQuery("membersByMeetingFormResterTrue", async () => {
    const response = await API.getMembersByMeeting({
      meetingId,
      fromReestr: true,
    })
      .then((res) => res.data)
      .catch((err) => console.log("Fetch Members Error", err));

    return response;
  });

  const {
    data: meetingMember,
    isLoading: meetingMemberLoading,
    error: meetingMemberError,
  } = useQuery("membersByMeetingFormResterFalse", async () => {
    const response = await API.getMembersByMeeting({
      meetingId,
      fromReestr: false,
    })
      .then((res) => res.data)
      .catch((err) => console.log("Fetch Members Error", err));

    return response;
  });

  useEffect(() => {
    return () => {
      localStorage.removeItem("newMeetnigId");
    };
  }, []);

  if (
    meetingMemberLoading ||
    meetingAgendaLoading ||
    meetingLoading ||
    simpleMeetingMemberLoading
  ) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={"80vh"}
      >
        <CircularProgress
          color="success"
          style={{ width: "100px", height: "100px" }}
        />
      </Box>
    );
  }
  if (meetingError || meetingMemberError || meetingAgendaError) {
    alert("Ma'lumot kelmadi");
    setTimeout(() => {
      navigate("/");
    }, 3000);
  }
  return (
    <Wrapper>
      <div className="container">
        <Breadcrumb list={breadcrumbList} />
        <div className="meeting-created-box">
          <h2 className="meeting-created-title">{t("navoiMeeing")}</h2>
          <AssemblyData meeting={meeting} />
          <MeetingParticipants
            meetingMember={
              !!meetingMember && !!simpleMeetingMember
                ? [...meetingMember, ...simpleMeetingMember]
                : []
            }
          />
          {meetingAgenda.map((el, idx) => (
            <AgendaInfo data={el} count={idx + 1} />
          ))}

          <div className="meeting-created-box-footer">
            <MyButton
              border="#208667"
              color="#208667"
              background="#FFFFFF"
              onClick={() => {
                navigate("/admin/meetings/create#general");
                localStorage.setItem("newMeetnigId", JSON.stringify(meeting));
              }}
            >
              {t("back")}
            </MyButton>
            <MyButton
              border="#208667"
              color="#FFF"
              background="#208667"
              onClick={() => navigate("/admin/meetings")}
            >
              {t("next")}
            </MyButton>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default MeetingCreated;
