import React from "react";
import styled from "styled-components";

const ArchiveMeetingItem = ({ el, active, handleClick }) => {
  return (
    <ArchiveMeetingItemBox
      className={`meeting-item ${el.id === active ? "active" : ""}`}
      onClick={() => handleClick(el.id)}
    >
      <span className="meeting-protocol">{el?.protocolNumber}</span>
      <span className="meeting-name">{el?.companyName}</span>
      <span className="meeting-date">
        {new Date(el?.startDate).toLocaleString()}
      </span>
    </ArchiveMeetingItemBox>
  );
};

export default ArchiveMeetingItem;

const ArchiveMeetingItemBox = styled.li`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 22px 20px;
  border-radius: 12px;
  border: 2px solid #ddf8f0;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s linear;

  .meeting-item:hover {
    border: 2px solid #208667;
    transition: all 0.3s linear;
    transform: scale(1.01);
  }

  .meeting-protocol {
    display: inline-block;
    padding: 4px 9px;
    border-radius: 8px;
    border: 1px solid #208667;
  }

  .meeting-name {
    font-size: 18px;
    font-weight: 700;
  }

  .meeting-date {
    color: #121212;
    font-size: 14px;
  }

  .active {
    border: 2px solid #208667;
  }
`;
