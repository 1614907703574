import React, { createContext, useEffect } from "react";
import { useState } from "react";
import { API } from "../../../../../services/api";
import { Box, CircularProgress } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const AgendaContext = createContext(null);

const getAgendas = async ({
  setAgendas,
  meetingId,
  memberRole,
  setActiveAgenda,
  navigate,
  setAgendaPromise,
}) => {
  setAgendaPromise((prev) => ({ ...prev, isLoading: true }));
  const response = await API.getAgendaByMeeting({ id: meetingId })
    .then((res) => {
      setAgendas(res.data);
      if (!!memberRole.agendaId) {
        const agenda = res.data.find((el) => el.id === memberRole.agendaId);
        setActiveAgenda(agenda);
      } else {
        setActiveAgenda(res.data[0]);
      }
      return res.data;
    })
    .catch((err) => {
      setTimeout(() => {
        navigate(-1);
      }, 3000);
      setAgendaPromise((prev) => ({ ...prev, isError: err.message }));
      return err;
    })
    .finally(() => {
      setAgendaPromise((prev) => ({ ...prev, isLoading: false }));
    });
  return response;
};

const MeetingDetailContext = ({ children }) => {
  const [agendas, setAgendas] = useState([]);
  const [activeAgenda, setActiveAgenda] = useState({});
  const [isVotes, setIsVotes] = useState(false);
  const [agendaPromise, setAgendaPromise] = useState({
    isLoading: true,
    isError: null,
  });
  const { meetingId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const memberRole = localStorage.getItem("memberRole")
    ? JSON.parse(localStorage.getItem("memberRole"))
    : {};
  const refetch = async () =>
    await getAgendas({
      setAgendaPromise,
      setAgendas,
      meetingId,
      memberRole,
      setActiveAgenda,
      navigate,
    });

  useEffect(() => {
    refetch();
  }, [memberRole?.meetingId]);

  useEffect(() => {
    if (!!activeAgenda?.id) {
      localStorage.setItem(
        "memberRole",
        JSON.stringify({
          ...memberRole,
          agendaId: activeAgenda?.id,
        })
      );
    }
    if (!state) {
      navigate(-1);
    }
  }, [activeAgenda]);

  if (agendaPromise.isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={"80vh"}
      >
        <CircularProgress
          color="success"
          style={{ width: "100px", height: "100px" }}
        />
      </Box>
    );
  }

  if (agendaPromise.isError) {
    return <p>Error: {agendaPromise.isError}</p>;
  }

  return (
    <AgendaContext.Provider
      value={{
        agendas,
        activeAgenda,
        setActiveAgenda,
        isVotes,
        setIsVotes,
        refetch,
      }}
    >
      {children}
    </AgendaContext.Provider>
  );
};

export default MeetingDetailContext;
